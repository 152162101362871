#home-page-main-banner {
  position: relative;
  background-color: #f3f3f3;
  width: 100%;
  --swiper-theme-color: #fff;
  overflow: hidden;
  aspect-ratio: 7 / 9;
  img, picture, .video-banner {
    aspect-ratio: 7 / 9;
  }
  @media (min-width: 800px) {
    aspect-ratio: 32 / 13;
    img, picture, .video-banner {
      aspect-ratio: 32 / 13;
    }
  }
  .swiper {
    opacity: 0;
    height: 100%;
    &-initialized {
      opacity: 1;
    }
    .swiper-button-prev,
    .swiper-button-next {
      transition: all 0.3s linear;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      &:after {
        font-size: 20px;
      }
    }
    @media (min-width: @screen-md-min) {
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .main-banner-item {
    &.video-banner {
      background-color: #111;
      a {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
      }
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: none;
        z-index: 1;
      }
      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


#offers-banner {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s linear;
  visibility: hidden;
  .main-banner-image {
    animation: slideOffersBanner 10s linear infinite;
    animation-play-state: paused;
  }
  &.loaded {
    opacity: 1;
    visibility: visible;
    .main-banner-image {
      animation-play-state: running;
    }
  }
  a {
    display: block;
  }
  img {
    display: block;
    transform: scaleX(1.01);
  }
}

@-webkit-keyframes slideOffersBanner {
  from {
    transform: translateX(0%); /* Initial position */
  }
  to {
    transform: translateX(-100%); /* Scrolling position */
  }
}
@keyframes slideOffersBanner {
  from {
    transform: translateX(0%); /* Initial position */
  }
  to {
    transform: translateX(-100%); /* Scrolling position */
  }
}

