// ********************************************************************************************

// modal newsletter signup form

#modal-news-signup {
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .modal-dialog {
    width: 90vw;
    margin: 50px 5vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    @media (min-width: @screen-md-min) {
      width: 60vw;
      margin: 50px 20vw;
    }
    @media (min-width: @screen-lg-min) {
      width: 40vw;
      margin: 50px 30vw;
    }
  }
  .modal-header {
    position: relative;
    background-color: white;
    h1 {
      .main-font-bold();
      font-size: 6vw;
      text-transform: uppercase;
      margin: 20px 0 0;
      .lovell-blue {
        color: @lovell-blue;
      }
      @media (min-width: @screen-md-min) {
        font-size: 4vw;
      }
      @media (min-width: @screen-lg-min) {
        font-size: 2.6vw;
      }
    }
    h2 {
      font-size: 2.6vw;
      margin-top: 0;
      @media (min-width: @screen-md-min) {
        font-size: 2vw;
      }
      @media (min-width: @screen-md-min) {
        font-size: 1.5vw;
      }
    }
  }
  .modal-body {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .modal-footer {
    padding: 20px 20px 40px;
    text-align: center;
    #modal-email {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      border: 1px solid @lovell-border-color;
      width: 90%;
      font-size: 16px;
      padding: 10px;
    }
    #modal-signup-submit, #open-news-modal {
      display: block;
      background: @lovell-blue;
      border: none;
      margin: 20px auto 0;
      text-transform: uppercase;
      color: white;
      padding: 10px 60px;
      font-size: 12px;
    }
  }
  .modal-response {
    text-align: center;
    padding-bottom: 10px;
    .error {
      color: red;
    }
  }
}

#main-banner-items-row {
  opacity: 1 !important;
  video {
    width: 100%;
    &::-webkit-media-controls {
      display: none;
    }
  }
  .banner-video-overlay {
    position: absolute;
    background: #01aef0;
    top: 50%;
    right: 10%;
    padding: 20px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

// App-specific stuff
.app-only {
  display: none !important;
}

body.android,
body.ios {
  .app-download-banner,
  .app-exclude {
    display: none !important;
  }

  .app-only {
    display: block !important;
  }
}
