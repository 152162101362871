.container.slider--full-width {
  @media (min-width: @screen-md-min) {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.slider {
  position: relative;
  margin: 0 -@grid-gutter-width/2;
  @media (min-width: @screen-md-min) {
    margin: 0;
  }
  .swiper {
    opacity: 0;
    transition: opacity 0.2s linear;
    &-initialized {
      opacity: 1;
    }
    &-slide {
      height: auto;
    }
    &-pagination {
      position: static;
      margin-top: 20px;
    }
    &-button-prev,
    &-button-next {
      width: 21px;
      height: 21px;
      &::after {
        font-size: 9px;
      }
      background-color: #999;
      border-radius: 50%;
      color: #fff;
    }
    &-button-prev {
      transform: translateX(-24px);
      left: -10px;
    }
    &-button-next {
      transform: translateX(24px);
      right: -10px;
    }
    &-navigation-disabled {
      ~ .swiper-button-prev,
      ~ .swiper-button-next {
        display: none;
      }
    }
  }

  &__item-content-wrapper {
    height: 100%;
    > a {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;
    }
  }
  &__product-class {
    text-align: left;
    overflow: hidden;
    position: relative;
    display: none;
    span {
      z-index: 10;
      text-align: center;
      color: @text-main;
      font-size: 11px;
      line-height: @font-size-large;
      width: 38%;
      display: block;
    }
  }
  &__item-image {
      &::before {
        content: '';
        display: block;
        background-color: #eee;
        mix-blend-mode: darken;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all .2s linear;
        z-index: 1;
      }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      background-color: #eeeeee;
      // This forces *slightly* better smoothing of resized images on webkit
      transform: translateZ(0);
      &::before {
        display: block;
        content: '';
        background-color: #eee;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
      }
    }

    position: relative;
    height: auto !important;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .slider__product-class {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: #fff;
      color: #333;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      padding: 5px;
      display: block;
      z-index: 10;
      &[data-content="USE CODE: <strong>EXTRA20</strong>"] {
        background-color: #111;
        color: #eb4c27;
      }
    }
    &:hover,
    &:active {
      .chooser-discount-flash {
        overflow: visible;
        white-space: normal;
      }
    }

    .chooser-discount-flash {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #000;
      color: #fff;
      text-align: center;
      font-size: 11px;
      text-transform: uppercase;
      padding: 5px;
      &.free-id-badge {
        width: 30px;
        z-index: 3;
        height: 30px;
        top: 10px;
        right: 10px;
        left: auto;
        padding: 3px;
        background: #000;
        display: flex;
        justify-content: center;
        transition: width 0.3s ease-in-out;
        border-radius: 5px;
        @media (min-width: @screen-sm-min) {
          width: 32px;
          height: 32px;
        }
        img {
          height: 100%;
          width: auto;
          position: static;
        }
      }
    }
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    @media(min-width: @screen-md-min) {
      flex-direction: row;
      gap: 20px;
      align-items: flex-start;
    }
  }
  &__item-title {
    text-align: left;
    .main-font();
    margin: 10px 0 0;
    font-size: 13px;
    flex: 1;
  }
  &__item-cost-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    @media(min-width: @screen-md-min) {
      font-size: 13px;
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &__item-cost {
    color: @text-main;
    margin: 0 0 15px 0;
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0 !important;
    .main-font-bold();

    @media (min-width: @screen-md-min) {
      margin: 0;
      font-size: 13px;
    }

    &.rrp {
      color: #999;
      text-decoration: line-through;
      font-family: @font-main !important;
      font-size: 13px;
      span {
        display: none;
      }
    }

    &.now {
      color: #000;
      .main-font-bold();
      margin-right: 10px;

      @media (min-width: @screen-md-min) {
        margin-right: 0;
      }
    }
  }

  .item-tags {
    margin-top: 10px;
    padding-top: 10px;
    font-size: 13px;
    border-top: 1px solid @border-color;
    text-align: left;
  }

  &.feature-slider {
    padding-top: 20px;
    padding-bottom: 30px;
    
  }
}

/**
*
* modifiers
*
**/

.swiper-pagination,
.swiper-button-prev,
.swiper-button-next {
  --swiper-theme-color: #111;
}

.swiper-lazy-preloader {
  --swiper-theme-color: #fff;
}


.home-page-main-banner {
  .swiper-pagination-bullet {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 1);
  }
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
}

.tabbed-sliders {
  margin-bottom: 30px;
  margin-top: 30px;
  @media (min-width: @screen-md-min) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 50px 36px;
    justify-content: space-between;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    @media (min-width: @screen-md-min) {
      text-align: left;
      flex: 1;
    }
  }
  &__header {
   display: flex;
   justify-content: center;
    @media(min-width: @screen-md-min) {
      flex-wrap: nowrap;
    }
    &-title {
      flex: 0 0 100%;
      text-align: center;
      color: #fff;
      margin: 0;
      .main-font-bold();
      @media(min-width: @screen-md-min) {
        display: flex;
        flex: 1 1 auto;
      }
    }
    &-items {
      .main-font-bold();
      color: #fff;
      border: 1px solid #111;
      flex: 0 0 auto;
      height: max-content;
      &--mobile {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        @media(min-width: @screen-md-min) {
          display: none !important;
        }
      }
      &--full {
        display: none !important;
        @media(min-width: @screen-md-min) {
          display: flex !important;
        }
      }
    }
    &-item {
      background: none;
      color: #333;
      margin: 0;
      padding: 10px 20px;
      border-bottom: none;
      position: relative;
      cursor: pointer;
      
      &.is-active {
        background: #111;
        color: #fff;
        
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -8px;
          width: 0;
          height: 0;
          border-top: solid 8px #111;
          border-left: solid 8px transparent;
          border-right: solid 8px transparent;
        }
      }

      @media (min-width: @screen-md-min) {
        font-size: 12px;
      }
    }
    &-item-count {
      margin-left: 3px;
    }
  }
  
  &__sliders {
    text-align: center;
    padding-top: 20px;
    @media (min-width: @screen-md-min) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
  &__slider-title {
    .main-font-bold();
  }
  &__slider-item {
    visibility: hidden;
    height: 0;

    opacity: 0;
    h2 {
      padding: 0 20px;
    }
    p {
      padding: 0 20px;
    }
    &.is-active {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }
  
}

