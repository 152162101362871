.alert {
  padding: 20px 50px;
  font-size: 1.4rem;
  text-align: center;
  margin: 0px 0 20px;
  position: relative;
  color: #fff;
  width: 100%;
  a {
    color: rgba(255,255,255,0.8);
  }
  &__close {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      background: rgba(0,0,0,0.3);
    }
  }
  &--success {
    background-color: @lovell-green-light;
    border: 1px solid darken(@lovell-green-light, 10%);
  }
  &--warning {
    background-color: @lovell-orange-light;
    border: 1px solid darken(@lovell-orange-light, 10%);
  }
  &--error {
    background-color: @lovell-red-light;
    border: 1px solid darken(@lovell-red-light, 10%);
  }
  &--info {
    background-color: @lovell-blue-light;
    border: 1px solid darken(@lovell-blue-light, 10%);
  }
}