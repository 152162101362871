@import '../less/bootstrap/variables.less';

.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

.main-font() {
  font-family: @font-main;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.main-font-bold() {
  font-family: @font-main-bold;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.skew (@deg) {
  -webkit-transform: skewX(@deg);
  transform: skewX(@deg);
}

// styles for flex layouts
.flex-container(@wrap: wrap, @align-items: center, @justify: flex-start, @direction: rowcol) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-direction: rowcol;
  -ms-flex-direction: rowcol;
  flex-direction: rowcol;

  -webkit-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;

  -webkit-box-align: @align-items;
  -webkit-align-items: @align-items;
  -ms-flex-align: @align-items;
  align-items: @align-items;

  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-box-pack: @justify;
  -webkit-justify-content: @justify;
  -ms-flex-pack: @justify;
  justify-content: @justify;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
}
.flex-item(@grow:1, @shrink:1, @basis:auto, @align: center) {
  -webkit-box-flex: @grow;
  -webkit-flex-grow: @grow;
  -ms-flex-positive: @grow;
  flex-grow: @grow;
  -webkit-flex-shrink: @shrink;
  -ms-flex-negative: @shrink;
  flex-shrink: @shrink;
  -webkit-flex-basis: @basis;
  -ms-flex-preferred-size: @basis;
  flex-basis: @basis;
  -webkit-align-self: @align;
  -ms-flex-item-align: @align;
  align-self: @align;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

.tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

.center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.hide-text() {
  font: ~'0/0' a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-hide() {
  .hide-text();
}
.nav-divider(@color: #e5e5e5) {
  height: 1px;
  margin: ((@line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: @color;
}

.reset-filter() {
  filter: e(%('progid:DXImageTransform.Microsoft.gradient(enabled = false)'));
}

.table-row-variant(@state; @background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.@{state},
    > th.@{state},
    &.@{state} > td,
    &.@{state} > th {
      background-color: @background;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.@{state}:hover,
    > th.@{state}:hover,
    &.@{state}:hover > td,
    &:hover > .@{state},
    &.@{state}:hover > th {
      background-color: darken(@background, 5%);
    }
  }
}

// css for discount flash on products
.discount-flash(@width: 80px) {
  opacity: 1;
  background: #eb212e;
  color: white;
  text-align: center;
  width: 100%;
  padding: 2px;
}

.chooser-discount-flash() {
  .discount-flash();
  font-size: 11px;
  text-transform: uppercase;

  &.free-item-flash {
    background-color: @lovell-blue;
  }
  &.free-express-flash {
    background-color: @lovell-blue;
  }
}

// selectbox styling mixin - add class 'selectbox' to a select's parent to apply
.selectbox(@width: 100%, @height: 48px) {
  .sod_select,
  .sod_select * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .sod_select {
    width: @width;
    padding: 0px 0px 0 0px;
    background: #ffffff;
    color: #444444;
    font-size: 13px;
    text-align: left;
    outline: 0;
    cursor: pointer;
    position: relative;
    display: block;
  }

  /* Down arrow */
  .sod_select .sod_label:after {
    content: url(../../images/icons/chevron-down-outline.svg);
    width: 14px;
    height: auto;
  }

  .sod_select.disabled {
    border-color: #828282;
    color: #b2b2b2;
    cursor: not-allowed;
  }

  .sod_select .sod_label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: @height;
    height: @height;
    border: 1px solid #b3b3b3;
    .flex-item(1,1,auto);
    .flex-container(nowrap,center, space-between);
    padding: 0 10px;
    z-index: 20;
    @media (min-width: @screen-md-min) {
      height: 40px;
      line-height: 40px;
    }
  }
  /* Options list wrapper */
  .sod_select .sod_list_wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    height: auto;
    width: 100%;
    margin: 0 0px 0px;
    background: #ffffff;
    border: 1px solid #b3b3b3;
    border-top: none;
    color: #444444;
    z-index: 30;
    max-height: 350px;
    overflow-y: auto;
    text-align: left;
  }

  /* Shows the option list (don't edit) */
  .sod_select.open .sod_list_wrapper {
    display: block;
  }

  /* Don't display the options when  */
  .sod_select.disabled.open .sod_list_wrapper {
    display: none;
  }

  /* When the option list is displayed above the SoD */
  .sod_select.above .sod_list_wrapper {
    top: auto;
    bottom: 100%;
    border-top: 1px solid #b3b3b3;
    border-bottom: none;
  }

  /* Options list container */
  .sod_select .sod_list {
    display: block;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  /* All the options. Keep the first three lines for truncating... */
  .sod_select .sod_option {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px 10px;
    list-style-type: none;
    font-size: 11pt;
  }

  /* Optgroups */
  .sod_select .sod_option.optgroup,
  .sod_select .sod_option.optgroup.disabled {
    background: inherit;
    color: #939393;
    font-size: 10px;
    font-style: italic;
  }

  /* Children of an optgroup */
  .sod_select .sod_option.groupchild {
    padding-left: 20px;
  }

  /* Used together with placeholderOption / data-placeholder-option */
  .sod_select .sod_option.is-placeholder {
    display: none;
  }

  /* Disabled option */
  .sod_select .sod_option.disabled {
    background: inherit;
    color: #cccccc;
  }

  /* Hover state for options, also used when a user uses his/hers up/down keys */
  .sod_select .sod_option.active {
    background: #f7f7f7;
    color: #333333;
  }

  /*Make room for the check mark */
  .sod_select .sod_option.selected {
    padding-right: 25px;
  }

  /* Add a .no_highlight class to you SoD to hide the check mark and don't bold the option */
  .sod_select.no_highlight .sod_option.selected {
    font-weight: 300;
  }
  .sod_select.no_highlight .sod_option.selected:before {
    display: none;
  }

  /* Hide native select */
  .sod_select select {
    display: none !important;
  }

  /* The native select in touch mode. Keep this first line. Sorry, keep everything. */
  .sod_select.touch select {
    -webkit-appearance: menulist-button;

    position: absolute;
    top: 0;
    left: 0;
    display: block !important;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
}

// mixin for styling form submit buttons
.submit-button(@width: 25%, @height: 48px) {
  background: #111;
  .main-font();
  border: none;
  width: @width;
  border-radius: 0;
  float: right;
  height: @height;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 1.2rem;
  vertical-align: top;
  cursor: pointer;
  &:hover {
    background: #444;
  }
  @media (min-width: @screen-md-min) {
    height: 40px;
  }
}

.text-input(@width: 100%, @border: 1px solid #b3b3b3) {
  border: @border;
  width: @width;
  height: 48px;
  padding: 12px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
  margin-bottom: 0;
  &:focus {
    border: 1px solid @lovell-blue;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: @screen-md-min) {
    height: 40px;
    padding: 8px;
  }
}

.checkbox() {
  input[type='checkbox'] {
    border: 1px solid red !important;
    border-radius: 0 !important;
  }
}

.lovell-button(@color: @lovell-blue, @width: 100%) {
  background-color: @color;
  padding: 12px;
  display: inline-block;
  color: white !important;
  border: none !important;
  width: @width;
  font-size: 13pt;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: white;
    text-decoration: none;
  }
  &.fa {
    margin-left: 10px;
  }
  img {
    width: 8px;
    height: 14px;
    margin-left: 3px;
  }
}

.page-title() {
  font-size: 18pt;
  text-align: center;
  padding: 20px;
  margin: 0;
}

.section-title() {
  background: none;
  font-size: 16pt;
  text-align: center;
  font-weight: bold;
  padding: 15px;
  margin-top: 0;
}

// Vendor specific flex styles for older browsers (or not so old, like Safari 8)
.displayFlex {
  // ~2011 "awkward tweener phase"
  display: -ms-flexbox;
  display: flexbox;

  // standard
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}
.flex(@value) {
  -webkit-flex: @value;
  -ms-flex: @value;
  flex: @value;
}

.chooser-items() {
  @media (min-width: @screen-sm-min) {
    display: flex;
    flex-wrap: wrap;
  }
  > .item {
    > a {
      color: @text-main;
      text-decoration: none;
      display: block;
      height: 100%;
      .media {
        display: flex;
        flex-direction: column;
        .media-left {
          flex: 0 0 48.5%;
          max-width: 48.5%;
          padding: 0;
          display: flex;
          align-items: center;
          @media (min-width: @screen-sm-min) {
            flex: 0 0 100%;
            max-width: 100%;
          }
          .item-image {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            aspect-ratio: 1 / 1;
            width: 100%;

            @supports not (aspect-ratio: 1 / 1) {
              &::before {
                float: left;
                padding-top: 100%;
                content: "";
              }

              &::after {
                display: block;
                content: "";
                clear: both;
              }
            }

            > .chooser-discount-flash {
              display: none;
            }
            .product-class {
              display: none;
            }
            .item-flashes {
              .product-class {
                display: none;
                &--flash {
                  display: block;
                }
              }
            }
            &::before {
                content: '';
                display: block;
                background-color: #eee;
                mix-blend-mode: darken;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: all .2s linear;
                z-index: 1;
            }
            .loading-spinner {
              align-self: center;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            // This forces *slightly* better smoothing of resized images on webkit
            transform: translateZ(0);
            
            &::after {
              content: '';
              display: block;
              background-color: #eee;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }
        .media-body {
          padding: 0px 20px 0px 3%;
          @media (min-width: @screen-sm-min) {
            padding: 10px 0 0;
            width: 100%;
          }
        }
      }
      .item-header {
        position: relative;
        font-size: 9pt;
        max-height: 22px;
        // fix the height of the class header on tablet and higher to even up the tops of the images in the grid
        @media (min-width: @screen-sm-min) {
          height: 22pt;
        }
      }
      .item-image {
        clear: left;
        position: relative;
        width: 100%;
        .chooser-discount-flash {
          .chooser-discount-flash();
        }
      }
      .item-flashes {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        .product-class {
          display: none;
          &--flash {
            .product-class();
            
          }
        }
        .chooser-status {
          width: 100%;
          display: block;
          background-color: #fff;
          padding: 2px;
          font-size: 11px;
          color: #333;
          text-align: center;
          text-transform: uppercase;
          &-new {
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
          }
          &-preorder {
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
          }
          &-multibuy {
            background-color: #00afed;
            color: #fff;
          }
        }
      }
      .item-content {
        .item-colour {
          margin-bottom: 0;
          line-height: 1.1;
          display: none;
        }
        .thumbSizeSummary {
          padding: 10px 0 0;
          flex: 0 1 auto;
        }
        .thumbContentPrice {
          padding-top: 10px;
          line-height: 14px;
          margin: 0;
          text-align: left;
          @media (min-width: @screen-sm-min) {
            text-align: right;
          }
          > .thumboffer {
            color: @sale-red;
            font-size: 16px;
          }
          > .price {
            font-weight: normal;
            font-size: 16px;
          }
          .rrp {
            margin-bottom: 3px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.product-class() {
  width: 100%;
  border: 1px solid @nav-tabs-border-color;
  display: block;
  background-color: #fff;
  text-align: center;
  padding: 2px;
  font-size: 11px;
  color: #333;
  text-align: center;
  text-transform: uppercase;

  // Extra 20 promo colours

}
