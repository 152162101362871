/*CSS: global.css */

/* These styles are used by ALL sites with individual site styles being in their own CSS files            */

/* Many styles should exist in these individual files to provide the various look of each individual site */

/* Individual sites shouldn't have many unique styles                                                     */

form {
  margin: 0px;
  padding: 0px;
}

.clear {
  background: none;
  border: none;
}

a:link.nice {
  color: #666666;
  text-decoration: none;
}

a:visited.nice {
  color: #555555;
  text-decoration: none;
}

a:hover.nice {
  color: #666666;
  text-decoration: none;
}

a:active.nice {
  color: #ff0000;
  text-decoration: none;
}

.tiny {
  font-size: 7pt;
}

input.inputRight {
  text-align: right;
}

img.flag {
  margin-left: 10px;
  margin-right: 5px;
}

/* References to other sites */

.lovellrugby {
  color: #0093d1;
}

.lovellsoccer {
  color: #d60000;
}

.wbr {
  color: #8c181b;
}

.warning {
  color: #ff0000;
}

.readonly {
  background: #cccccc;
  color: #333333;
  border: 1px solid #cccccc;
}

.small,
.smalllight {
  font-size: 0.85em;
  margin-top: 0em;
  margin-bottom: 0em;
}

.smalllight {
  font-weight: normal;
  color: #999999;
}

.smallSpace {
  font-size: 0.85em;
  margin-top: 0em;
  margin-bottom: 0.25em;
}

.box,
.sectionBox {
  border: 1px solid #666666;
  background: #e5e5e5;
  font-size: 9pt;
  padding: 5px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin: 5px 0px;
}

.okBox,
.errorBox,
.noticeBox,
.groupBox,
.sectionBox,
.alertBox,
.ghostBox {
  background-color: #bce5bc;
  border: 2px solid #33aa33;
  padding: 15px;
  margin: 10px;
}

.sectionBox {
  background-color: #72d2fb;
  border: 2px solid #0093d1;
}

.errorBox {
  background-color: #e5bcb5;
  border: 2px solid #aa3333;
}

.alertBox {
  background-color: #ffec5b;
  border: 2px solid #5b5b00;
}

.noticeBox {
  background-color: #a3b0ff;
  border: 2px solid #464fcd;
  font-weight: bold;
}

.groupBox {
  background-color: #ffffff;
  display: inline-block;
  border: 2px dotted #999999;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.ghostBox {
  background-color: #e5e5e5;
  border: 2px solid #cccccc;
  color: #999999 !important;
}

/* For expandable call stack in SQL logging */

.showSQLLog {
  display: block !important;
}

.SQLLogIcon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: inline-block;
  background: url(../responsive-images/plus.svg) no-repeat center center;
  background-size: contain;
}

.SQLLogIcon.open {
  background-image: url(../responsive-images/minus.svg);
}
