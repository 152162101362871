.video {
  position: relative;
  video {
    width: 100%;
    height: auto;
  }
  &__poster {
    width: 100%;
    height: auto;
  }
  video {
    display: none;
  }
  &.has-play-button:not(.is-playing) {
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 10;
      border: 2px solid white;
      border-radius: 50%;
      width: 58px;
      height: 58px;
      cursor: pointer;
      padding-left: 6px;
      transform: translate(-50%, -50%);
      .flex-container(nowrap,center,center);
      opacity: 0.7;
      content: '\e037';
      font-family: 'Material Symbols Outlined';
      font-size: 30px;
      color: white;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
  &__close-button {
    position: absolute;
    right: 5px;
    left: auto;
    visibility: hidden;
    top: 5px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 12;
    color: white;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    cursor: pointer;
    .flex-container(nowrap,center,center);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    &:after {
      content: '\e5cd';
      font-family: 'Material Symbols Outlined';
      font-size: 26px;
      color: white;
    }
  }
  &.is-playing {
    .video__poster {
      display: none !important;
    }
    video {
      display: block;
    }
    .video__play-button {
      display: none;
    }
  }
}
