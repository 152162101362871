.img {
  height: auto;
  width: 100%;
  max-width: 100%;
  display: block !important;
  &--mobile {
    @media(min-width: @screen-sm-min) {
      display: none !important;
    }
  }
  &--full {
    display: none !important;
    @media(min-width: @screen-sm-min) {
      display: block !important;
    }
  }
}
