#social {
  margin-top: 40px;
  margin-bottom: 40px;
  .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: @screen-md-min) {
      justify-content: flex-start;
    }
    .social-link {
      margin: 0 18px;
      @media (min-width: @screen-md-min) {
        margin: 0 30px 0 0;
      }
      & a {
        color: #fff;
        font-size: 30px;
      }
    }
  }
}
