.splash-page {
  h1 {
    font-size: 25px;
    @media (min-width: @screen-sm-min) {
      font-size: 30px;
    }
    @media (min-width: @screen-md-min) {
      font-size: 38px;
    }
  }
  h2 {
    font-size: 20px;
    @media (min-width: @screen-md-min) {
      font-size: 21px;
    }
  }
  h3 {
    font-size: 17px;
    @media (min-width: @screen-md-min) {
      font-size: 16px;
    }
  }
  h4 {
    font-size: 14px;
  }

  .feature-slider {
    h3 {
      font-size: 14px;
    }
  }

  .grid-panel--spaced {
    .grid-panel__item {
      position: relative;
      .lovell-button {
        width: auto;
        padding: 10px 40px;
        font-size: 14px;
      }
    }
  }

  .grid-panel__item {
    position: relative;
  }

  .grid-panel--sm-full-width {
    @media (max-width: @screen-sm-min) {
      margin: 0 -20px;

      .category {
        padding: 0 20px;
      }
    }
  }

  .grid-panel--ctas {
    .content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      margin-bottom: 5%;
      h3 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 10px;
      }
      .ctas {
        position: static;
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px;
        &.single {
          grid-template-columns: auto;
        }
        .cta {
          white-space: nowrap;
        }
      }
    }
  }

  .grid-panel--slider {
    display: block;
    .swiper-scrollbar {
      bottom: 0;
      height: 8px;
      margin-top: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      .swiper-scrollbar-drag{
        background: #ccc;
        height: 6px;
      }
    }
  }

  .page-title {
    padding: 20px 20px 10px;
    text-align: center;

    @media (min-width: @screen-sm-min) {
      padding: 50px 20px 10px;
    }
  }

  .page-breadcrumbs {
    margin: 0 auto 20px;
    text-align: center;
  }

  .page-description {
    width: 90%;
    margin: 0 auto 20px;
    text-align: center;
    line-height: normal;
    @media (min-width: @screen-md-min) {
      width: 900px;
      margin: 10px auto 20px;
    }
    a {
      display: inline-block;
    }
  }

  .page-section {
    &--boot-brands {
      margin-bottom: 30px;
    }

    &--boot-slider {
      margin-bottom: 30px;
    }

    &--boot-categories {
      margin-bottom: 30px;
    }

    &--boot-types-header {
      margin-bottom: 50px;
    }

    &--boot-types {
      margin-bottom: -20px;
    }

    &--club-teams {
      margin-bottom: 30px;
    }

    @media (min-width: @screen-sm-min) {
      &--boot-types {
        margin: 0 -10px;
      }
    }
  }

  .page-header {
    font-size: 22px;
    text-align: center;
    padding: 0 20px;

    @media (min-width: @screen-sm-min) {
      font-size: 27px;
    }
  }

  .page-text {
    margin: auto;
    text-align: center;

    @media (min-width: @screen-sm-min) {
      width: 66.66%;
    }
  }

  .menu {
    display: none;

    @media (min-width: @screen-sm-min) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 90px;
      list-style: none;
    }

    &__item {
      text-align: center;
      margin-right: 20px;
      padding-right: 20px;
      &:not(:last-child) {
        border-right: 1px solid #131313;
      }
    }

    &__link {
      .main-font-bold();
      color: #131313;
      text-decoration: none;
    }
  }

  .mobile-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px -10px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
    list-style: none;
    background-color: #fafafa;
    line-height: 30px;

    @media (min-width: @screen-sm-min) {
      display: none;
    }

    &__item {
      border-right: 1px solid #e0e0e0;
      padding: 0 20px;
      &:last-child {
        border-right: none;
      }
    }

    &__link {
      color: #131313;
      text-decoration: none;
    }
  }

  .tile {
    position: relative;
    display: block;
    color: white;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
      transition: 0.3s opacity ease-out;
      opacity: 1;
    }

    &__text {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 10px;
      z-index: 1;
      color: #fff;
      cursor: default;
    }

    &__name {
      margin: 0;
      font-size: 20px;
      .main-font-bold();

      @media (min-width: @screen-lg-min) {
        font-size: 26px;
      }
    }

    &__action {
      display: none;

      @media (min-width: @screen-sm-min) {
        display: block;
        margin: 5px 10px;
        font-size: 12px;
      }

      @media (min-width: @screen-md-min) {
        font-size: 14px;
      }
    }
  }

  .ctas {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30%;
    justify-content: center;
    gap: 40px;
    .cta {
      background-color: #fff;
      padding: 10px 20px;
      .main-font-bold();
      display: block;
    }
  }

  .full-tile {
    position: relative;
    color: white;
    display: block;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
      transition: 0.3s opacity ease-out;
      opacity: 1;
    }

    &:hover {
      &:after {
        opacity: 0.2;
      }
    }

    &__text {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &__title {
      font-size: 20px;
      margin: 2px auto;
      .main-font-bold();

      @media (min-width: @screen-lg-min) {
        font-size: 22px;
      }
    }

    &__description {
      margin: 2px auto;
      width: 75%;
      font-size: 14px;
    }

    &__action {
      display: inline;
      margin: 2px auto;
      border-bottom: 1px solid white;
      padding-bottom: 2px;
      font-size: 13px;

      @media (min-width: @screen-lg-min) {
        font-size: 16px;
      }
    }
  }

  .tile-pseudo-hover {
    &:hover {
      &:after {
        opacity: 0;
      }
      .tile__text {
        opacity: 0;
      }
    }
  }

  .tile-touch-hover {
    &:after {
      background: none;
    }
    .tile__text {
      opacity: 0;
    }
  }

  .category {
    &--separated {
      margin-bottom: 30px;
    }

    &__name {
      margin-bottom: 0;
      font-size: 20px;

      @media (min-width: @screen-sm-min) {
        font-size: 22px;
      }
    }

    &__description {
      margin: 8px auto 14px;
      @media (min-width: @screen-md-min) {
        max-width: 80%;
      }
      @media (min-width: @screen-lg-min) {
        max-width: 50%;
      }
    }

    &__button {
      display: inline-block;
      width: auto;
      border: 1px solid transparent;
      border-radius: 2px;
      padding: 10px 20px;
      background-color: #111;
      color: white;

      &:hover {
        border: 1px solid #111;
        background-color: white;
        color: #111;
      }
    }
  }


  .brands-section {
    padding: 20px 0;
    background-color: #fafafa;

    &--padded {
      padding: 20px 0 60px;
    }
  }

  .brands {
    display: flex;
    justify-content: center;
  }

  .brands__inner {
    display: flex;
    padding: 0;
    overflow: auto;
  }

  .brand-item {
    margin: 20px;
    min-width: 175px;
    height: auto;
    text-align: center;

    @media (min-width: @screen-lg-min) {
      min-width: 200px;
    }

    &:not(:last-child) {
      margin-right: 5px;
      border-right: 1px solid #e0e0e0;
      padding-right: 5px;
    }

    &__name {
      font-size: 17px;
    }
  }
}

.page-section {
  &--slider {
    padding: 0 10px;
    .page-section__item {
      background: #fafafa;
      padding: 10px 40px;
      margin: 10px;
      @media (min-width: @screen-md-min) {
        margin: 15px;
      }
      img {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }
  &--badges {
    .grid-panel__item {
      a {
        background: #fafafa;
        display: block;
        padding: 20px;
      }
      img {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }
  &--logos {
    .grid-panel {
      align-items: center;
      &__item {
        width: calc(~'50% - 20px');
        flex-basis: calc(~'50% - 20px');
        @media (min-width: @screen-sm-min) {
          width: calc(~'12.5% - 20px') !important;
          flex-basis: calc(~'12.5% - 20px') !important;
        }
        img {
          max-width: 80px;
          margin: auto;
        }
      }
    }
  }
}

.shop-by-sport {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: flex-start;
  &__item {
    flex: 0 0 50%;
    @media (min-width: @screen-sm-min) {
      flex: 0 0 20%;
    }
    text-align: center;
    a {
      text-decoration: none;
    }
    h3 {
      margin-top: 0;
    }
    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }
}
