html {
  // remove the default tap–highlight colour Mobile Safari applies
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 75%;
}

* {
  outline: none !important;
}

ion-icon {
  font-size: 22px;
  line-height: 22px;
  display: block;
}

body {
  .main-font();
  color: @text-main;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: none !important;
  transform: none !important;

  @media (min-width: @screen-sm-min) {
    padding-top: 0;
  }

  a {
    text-decoration: underline;
  }

  > .container {
    min-width: 300px;
  }
}

body {
  padding-top: 70px;

  .body-inner {
    transition: transform 3s ease-out;
    left: 0;
    padding-top: 70px;
  }

  @media (min-width: @screen-md-min) {
    padding-top: 0px;
  }

  &.filter-active {
    height: 100%;
    overflow: hidden !important;
  }

  @media (max-width: @screen-sm-max) {
    &.shop {
      .main-container {
        padding: 0 10px;
      }
    }
  }

  &.order {
    padding-top: 0;
    background: #f4f4f4;
  }

  &.fixed-header {
    padding-top: 80px;
  }

  &.navbar-open {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    .main-container {
      transform: translateX(calc(~'-100vw + ' @menu-offset));

      @media (min-width: @screen-sm-min) {
        transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
      }
    }

    #topbar {
      transform: translateX(calc(~'-100vw + ' @menu-offset));

      @media (min-width: @screen-sm-min) {
        transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
      }
    }

    footer {
      transform: translateX(calc(~'-100vw + ' @menu-offset));

      @media (min-width: @screen-sm-min) {
        transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
      }
    }

    #home-page-main-banner {
      transform: translateX(calc(~'-100vw + ' @menu-offset));

      @media (min-width: @screen-sm-min) {
        transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
      }
    }

    .chooser-banner {
      transform: translateX(calc(~'-100vw + ' @menu-offset));

      @media (min-width: @screen-sm-min) {
        transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
      }
    }

    nav.navbar {
      .navbar-header {
        .header-mobile {
          transform: translateX(calc(~'-100vw + ' @menu-offset));

          @media (min-width: @screen-sm-min) {
            transform: translateX(calc(~'-100vw + ' @menu-offset-sm));
            transform: translateX(0);
          }
        }
      }

      .navbar-close {
        left: 0;
        @media (min-width: @screen-sm-min) {
          left: ~'calc(@{menu-offset-sm} - @{menu-offset})';
        }
      }

      .navbar-mask {
        left: 0;
        opacity: 0.5;
        visibility: visible;
      }

      #navbar {
        left: @menu-offset;

        @media (min-width: @screen-sm-min) {
          left: @menu-offset-sm;
        }
      }
    }
  }
}

.main-container,
.header-mobile,
#topbar,
#home-page-main-banner,
.chooser-banner,
footer {
  transition: transform 0.15s ease-in-out;
}

body .sod_select .sod_label {
  .main-font();
  height: 48px;
  border-color: #ccc;

  &:after {
    font-size: 20px;
  }
}

*::before,
*::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

.clearfix {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .main-font-bold();
  margin-top: 20px;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  @media (min-width: @screen-md-min) {
    font-size: 28px;
  }
}
h2 {
  font-size: 20px;
  @media (min-width: @screen-md-min) {
    font-size: 25px;
  }
}

strong {
  .main-font-bold();
}

a {
  color: #111;
}

.h3 {
  font-size: 15pt !important;

  @media (min-width: @screen-sm-min) {
    font-size: 16pt !important;
  }

  @media (min-width: @screen-md-min) {
    font-size: 18pt !important;
  }
}

.centred {
  text-align: center;
}

p.small {
  font-size: 12px;
}

[data-reverse-order='true'] {
  flex-direction: row-reverse;
}

[data-align-mobile='left'] {
  text-align: left !important;
  margin-left: 0 !important;
  margin-right: auto !important;
}

[data-align-mobile='center'] {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

[data-align-mobile='right'] {
  text-align: right !important;
  margin-right: 0 !important;
  margin-left: auto !important;
}

@media (min-width: @screen-sm-min) {
  [data-align-tablet='left'] {
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  [data-align-tablet='center'] {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  [data-align-tablet='right'] {
    text-align: right !important;
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}

@media (min-width: @screen-md-min) {
  [data-align-desktop='left'] {
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  [data-align-desktop='center'] {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  [data-align-desktop='right'] {
    text-align: right !important;
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}

@media (min-width: @screen-lg-min) {
  [data-align-wide='left'] {
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  [data-align-wide='center'] {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  [data-align-wide='right'] {
    text-align: right !important;
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}

// remove ios default stying on text inputs
input[type='text'],
input[type='number'] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

.field-description {
  font-size: 9pt;
  font-style: italic;
}

.lovell-gray {
  background-color: @lovell-gray;
  color: @text-white;
}

.warning {
  color: @lovell-orange;
}

// removes the left/right margins from bootstrap rows
.row-no-padding {
  margin-left: 0;
  margin-right: 0;

  [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.full-width-image {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.text-right {
  text-align: right;
}

.center {
  text-align: center;
}

.lovell-blue {
  color: @lovell-blue;
}

.row-same-height {
  display: table;
  width: 100%;
  /* fix overflow */
  table-layout: fixed;
}

.input-text {
  // call text-input mixin - 100% width, 1px border by default
  .text-input();

  &.error {
    border-color: red !important;
  }
}

.red {
  color: red;
}

.message {
  font-weight: bold;
  font-size: 12pt;
  text-align: center;
  line-height: 13pt;
  padding: 10px;
}

.success-message {
  font-weight: normal;
  font-size: 11pt;
  text-align: center;
  line-height: 13pt;
  padding: 10px;
  background: @lovell-green;
  color: #fff;
  border-radius: 3px;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: @screen-sm-min) {
    margin-left: 8.33333333%;
    width: 83.333333333%;
  }

  @media (min-width: @screen-md-min) {
    margin-left: 0;
    width: auto;
  }
}

h1.page-title {
  .page-title();

  &.sub-title {
    @media (min-width: @screen-md-min) {
      text-align: left;
      padding-left: 0;
    }
  }
}

h2.page-title {
  .page-title();

  @media (min-width: @screen-md-min) {
    text-align: left;
    padding: 0 0 15px 0;
    margin-bottom: 25px;
    border-bottom: 2px solid #b3b3b3;
  }
}

h3 {
  margin-bottom: 20px;
}

.section-title {
  .section-title();
}

.container .home-page-main-banner img {
  max-height: 400px;
}

// stop chrome adding yellow backgrounds to inputs
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent !important;
  background-image: none;
  color: rgb(0, 0, 0);
}

// emulate a focus state for the styleable select box replacement
.field .sod_select {
  &.focus {
    .sod_label {
      border: 1px solid @lovell-blue;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  &:after {
    content: '\e5ca';
    font-family: 'Material Symbols Outlined';
    font-size: 30px;
    color: @lovell-light-green;
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 10px;
    display: none;
  }

  &.is-valid {
    .sod_label {
      border: 1px solid @lovell-light-green !important;
    }

    &:after {
      display: block;
    }
  }
}

.password-field {
  position: relative;

  .input-label {
    position: absolute;
    top: 0px;
    cursor: text;
    .text-input(100%, none);
    color: #b3b3b3;
  }
}

.checkbox-wrapper {
  width: 39px;
  display: inline-block;
  height: 39px;
  margin-right: 10px;
}

.checkbox-wrapper-small {
  width: 30px;
  display: inline-block;
  height: 30px;
  margin-right: 10px;
  margin-top: 30px;
}

.checkbox-image {
  margin-right: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
}

.lovell-checkbox {
  cursor: pointer;

  input {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
  }

  input:checked + span:before,
  input + span:before {
    visibility: visible;
    height: 30px;
    width: 30px;
    flex: 0 0 30px;
    color: #fff;
    display: block;
    margin-right: 7px;
    border: 1px solid #b3b3b3;
    display: block;
    align-items: center;
    justify-content: center;
    background: white;
  }

  input:checked + span:before {
    content: url('../../images/icons/checkmark-outline-white.svg');
  }

  &.error input + span:before {
    border-color: red;
    border-width: 2px;
  }

  input + span:before {
    content: '';
  }

  .field-error {
    color: red;
    font-size: 1.4rem;
  }
}

#breadCrumbs {
  padding-left: 0px;
}

@media (min-width: @screen-sm-min) {
  #breadCrumbs {
    margin: 0 0 20px 8.333333%;
    width: 83.333333%;
  }

  #breadCrumbs:empty {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-md-min) {
  #breadCrumbs {
    margin: 0 0 20px 0;
    width: 100%;
  }

  #breadCrumbs:empty {
    margin-bottom: 0;
  }
}

// apply styles to select replacement elements
// selects with a class of 'cs-select' call the mixin for styles
body {
  .selectbox();
}

select {
  display: none;
}

#pageContent {
  > .contentBox {
    clear: both;
  }
}

.lovell-button {
  .lovell-button();
}

.button-inactive {
  background-color: #666;
}

.button-wrapper {
  margin: 30px 0;
}

.lovell-green {
  background-color: @lovell-green;
}

.lovell-button-gray {
  .lovell-button(@lovell-gray) !important;
}

.header-text {
  padding: 25px 60px;
  text-align: center;
  font-size: 15pt;
  line-height: 17pt;
  font-weight: bold;
}

// move button CTA arrows away from button text
button,
a {
  // .fa is the class for font-awesome icons
  .fa {
    @media (min-width: @screen-sm-min) {
      // Don't include this margin on mobile, as we were having button right arrows wrapping on smaller screen sizes.
      margin-left: 20px;
    }
  }
}

@media (min-width: @screen-sm-min) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
    vertical-align: middle;
  }
}


// tick list - used in checkout and newsletter - bullet points with tick icon
ul.tick-list {
  padding-left: 10px;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  li {
    list-style: none;
    padding-left: 30px;
    display: flex;
    align-items: center;

    &:before {
      background-image: url('../../responsive-images/tick-icon.svg');
      background-repeat: no-repeat;
      background-position: 0 50%;background-size: 20px;
      margin-right: 6px;
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
}

.validation-error {
  color: #ea1b1b;
  list-style: outside none none;
  margin: 0px 0 0px;
  padding: 20px 0;
  font-size: 12pt;
  text-align: center;

  @media (min-width: @screen-md-min) {
    text-align: left;
  }

  &:empty {
    padding: 0;
  }
}

.form-error-field {
  .text-input(@width: 100%, @border: 1px solid #ea1b1b);
}

.competitionForm {
  // Display checkbox in competition form on same level as 'please check this box' text. Reduce size a little on desktop/wide layouts.
  img.checkbox-image {
    float: left;

    @media (min-width: @screen-md-min) {
      width: 20px;
      height: 20px;
    }
  }
}

// CMS page headers
.leftwide h1 {
  margin-bottom: 20px !important;
}

.field {
  // label goes left of field
  margin-bottom: 20px;
  text-align: left;
  clear: both;
  &:not(.field--preferences) {
    display: flex;
    align-items: center;
  }
  width: 100%;

  .field-error {
    color: red;
  }

  // call the selectbox mixin to affect all selects in the orderform - args = width, height
  .selectbox(100%, 48px);

  // small select box for title selection
  &.field-title {
    .selectbox(40%, 48px);

    @media (min-width: @screen-md-min) {
      .selectbox(40%, 40px);
    }
  }

  .field-name {
    flex: 0 0 40%;
    padding: 0px 1%;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
    display: table-cell;
  }

  .field-name-wide {
    width: 50%;
  }

  .field-control {
    flex: 1 1 65%;
    position: relative;

    .field-error {
      display: block;
    }

    &.is-valid {
      input {
        border-color: @lovell-light-green !important;
      }

      &:after {
        display: block;
      }
    }

    &:after {
      content: '\e5ca';
      font-family: 'Material Symbols Outlined';
      font-size: 30px;
      color: @lovell-light-green;
      position: absolute;
      left: 100%;
      top: 0;
      margin-left: 10px;
      display: none;
    }

    &.split {
      .displayFlex;

      .field-split {
        .flex(1);

        &:first-child {
          padding-right: 5px;
        }

        &:last-child {
          padding-left: 5px;
        }

        .sod_select {
          width: 100%;
        }
      }
    }
  }

  &.has-error {
    input,
    textarea {
      border-color: red !important;
      border-width: 2px !important;
    }
    .sod_select {
      .sod_label {
        border-color: red !important;
        border-width: 2px !important;
      }
    }
    .field-description {
      display: none !important;
    }
  }

  // call text-inout mixin to default style form inputs
  input:not([type='checkbox']):not([type='radio']) {
    .text-input(100%);

    &.validation-error {
      .text-input(100%, @border: 1px solid red);
    }

    color: #333;
  }
}

// css-only animated loading spinner
.loader,
.loader-dark {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
}

.loader {
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
}

.loader-dark {
  -webkit-animation: load5-dark 1.1s infinite ease;
  animation: load5-dark 1.1s infinite ease;
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@-webkit-keyframes load5-dark {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000000,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000000,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000000,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000000,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #000000;
  }
}

@keyframes load5-dark {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000000,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000000,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000000,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000000,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #000000;
  }
}

// loading spinner
.loading-spinner {
  pointer-events: none;
  width: 50px;
  height: 50px;
  border: 8px solid transparent;
  border-color: rgba(200, 200, 200, 1);
  border-top-color: rgba(50, 50, 50, 1);
  border-radius: 50%;
  margin: 20px auto;
  animation: loadingspin 1s linear infinite;
  z-index: 3;
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}

// 404 page
.content-404 {
  height: 500px;
  padding: 30px;
  .flex-container(nowrap, center, center, column);
  background-position: 50% 50%;
  background-size: cover;
  text-align: center;
  color: white;
  .main-font-bold();

  h1 {
    font-size: 3rem;
    letter-spacing: 1px;

    @media (min-width: @screen-sm-min) {
      font-size: 4rem;
    }

    @media (min-width: @screen-md-min) {
      font-size: 4vw;
    }
  }

  p {
    margin-bottom: 0;
    font-weight: normal;
    letter-spacing: 2px;

    @media (min-width: @screen-md-min) {
      font-size: 1.6rem;
    }

    @media (min-width: @screen-lg-min) {
      font-size: 1.8rem;
    }
  }

  img.full-width {
    width: 100%;
  }
}

/* For expandable call stack in SQL logging */
.showSQLLog {
  display: block !important;
}

.SQLLogIcon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: inline-block;
  background: url(../../responsive-images/plus.svg) no-repeat center center;
  background-size: contain;
}

.SQLLogIcon.open {
  background-image: url(../../responsive-images/minus.svg);
}

#register-form {
  .field-newsletter {
    p.small {
      padding-left: 36px;
    }

    .fa {
      margin-left: 10px;
    }
  }

  .accept-terms-checkbox {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
  }
}

.constrained {
  padding: 20px 5%;
}

@media (min-width: @screen-md-min) {
  .constrained {
    padding: 20px 16%;
  }
}

@media (min-width: @screen-lg-min) {
  .constrained {
    padding: 20px 20%;
  }
}

.account-menu {
  h2 {
    font-size: 2em;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
  }

  #address-form {
    p {
      text-align: center;
    }

    .address-display {
      .address-ref {
        font-size: 16pt;
        .main-font-bold();
        text-align: center;

        h3 {
          font-size: 1em;
          background-color: #f2f2f2;
          padding: 5px;
        }
      }
    }
  }
}

.find-address {
  .find-address-button {
    background-color: #01aef0;
    color: white;
    letter-spacing: 1px;
    border: 1px solid #01aef0;
    width: 160px;
    height: 40px;
    padding: 6px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
  }
}

.recently-viewed {
  margin-top: 30px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  position: relative;
  -webkit-box-orient: vertical;
}


@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .truncate { 

        display: block;

    }
}}


.truncate-link {
  cursor: pointer;
  .main-font-bold();
  text-align: center;
  display: table;
  margin: 10px auto 20px;
  font-size: 12px;
}

.select2-container--default
  .select2-dropdown
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #111;
}
.select2.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  font-size: 20px;
}
.select2.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  padding-left: 5px;
}
.select2.select2-container--default
  .select2-selection--multiple
  li.select2-selection__choice {
  background-color: #111;
  border: 1px solid #111;
  margin-left: 0;
  margin-right: 5px;
  border-radius: 3px;
  padding: 0;
  display: inline-flex;
  .select2-selection__choice__remove {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    border-right: none;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
    span {
      font-size: 20px;
      line-height: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      text-rendering: geometricPrecision;
    }
  }
  .select2-selection__choice__display {
    color: #fff;
    padding: 8px 20px;
  }
}

.radios {
  .radio {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  label {
    cursor: pointer;
  }
  input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: #111;
    width: 24px;
    height: 24px;
    border: 3px solid #111;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: #666;
    }
    &:checked {
      &::before {
        transform: scale(1);
      }
    }
  }
}

body.sitemap .contentBox {
  padding: 40px 0;
  @media (min-width: @screen-md-min) {
    columns: 2;
  }
}
body.sitemap .contentBox li {
  margin-bottom: 12px;
  list-style: none;
}

.bv-rnr__sc-1grr47e-0,
.gvGUSg {
  display: none !important;
}

// faqs
.faqs {
  margin: 50px auto;
  padding: 0 20px;
  max-width: 1024px;
  @media (min-width: @screen-md-min) {
    padding: 0;
  }
  .faq {
    align-items: center;
    transition: 0.3s all ease-in;
    border-bottom: 1px solid @lovell-border-color;
    padding: 25px 0;
    &__question {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;
      h3 {
        margin: 0;
        font-weight: normal;
        .main-font-bold();
        font-size: 16px;
      }
    }
    &__answer {
      display: none;
      transition: 0.3s all ease-in;
      padding: 20px 0;
    }
    ion-icon {
      font-size: 16px;
      width: 20px;
      transition: transform 0.2s linear;
      transform-origin: center;
      margin-left: 30px;
      margin-top: 4px;
      cursor: pointer;
      flex: 0 0 14px;
    }
    &.active {
      .faq__question {
        ion-icon {
          transform: rotate(180deg);
        }
      }
      .faq__answer {
        display: block;
      }
    }
  }
}
 
.resize {
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
}


.handle { /* Thin line seperator */
  position:absolute; 
  left:50%;
  top:0;
  bottom:0;
  width:5px;
  margin-left:-2px;
 
  background: rgba(255,255,255,.5);
  cursor: ew-resize;
}
 
.handle:after {  /* Big orange knob  */
    position: absolute;
    top: 50%;
    width: 30px;
    height: 80px;
    transform: translate(calc(-50% + 2px), -50%);
 
    content: '';
    color:#111;
    font-weight:bold;
    font-size:30px;
    text-align:center;
    line-height:54px;
 
    background-color: #ffffff; /* @orange */
    background-image: url('../../images/icons/pause-outline.svg');
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    border:1px solid #ffffff; /* darken(@orange, 5%) */
    border-radius: 30px;
    transition:all 0.3s ease;
    box-shadow:
      0 2px 6px rgba(0,0,0,.5), 
      inset 0 2px 0 rgba(255,255,255,.5),
      inset 0 30px 40px -20px rgba(5,5,5,.4); /* lighten(@orange, 20%)*/ 
}

.draggable:after {
    width: 30px;
    height: 40px;
    line-height:40px;
    font-size:30px;
}

.container .quicklinks {
  margin-left: -10px;
  margin-right: -10px;
  @media (min-width: @screen-md-min) {
    margin-left: 0;
    margin-right: 0;
  }
}

.quicklinks {
  position: relative;
  margin-bottom: 30px;
  .swiper-slide {
    width: auto;
    align-self: stretch;
    height: auto;
  }

  a, .button:hover, .button:focus, .button:active {
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    outline: 1px solid #666 !important;
    outline-offset: -1px;
    color: #111;
    white-space: nowrap;
    padding: 12px 20px;
    transition: all 0.2s ease-in-out;
    display: block;
    @media (min-width: @screen-md-min) {
      white-space: wrap;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }
    &:hover {
      background-color: #111;
      color: #fff;
    }
  }

      
  @media (min-width: @screen-md-min) {
    padding: 0 30px;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 10px;

    visibility: hidden;
    @media (min-width: @screen-md-min) {
      visibility: visible;
    }
    &::after {
      font-size: 16px;
      height: 16px;
      width: auto;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}


.breadcrumbs {
  display: none;
  gap: 5px;
  margin: 20px 0;
  padding: 0 10px;
  @media (min-width: 600px) {
    display: flex;
  }
  @media (min-width: @screen-md-min) {
    padding: 0 30px;
  }
  .breadcrumb {
    position: relative;
    white-space: nowrap;
    a, span {
      padding: 6px 6px 6px 18px;
      display: block;
      font-size: 11px;
    }
    a {
      transition: all 0.3s ease-out;
      &::before, &::after {
        display: block;
        content: '';
        background-color: #f3f3f3;
        position: absolute;
        width: 100%;
        height: 50%;
        left: 5px;
        top: 0;
        z-index: -1;
        transform: skew(30deg);
        transition: all 0.3s ease-out;
      }
      &::after {
        top: 50%;
        transform: skew(-30deg);
      }
      &:hover {
        color: #fff;
        &::before, &::after {
          background-color: #333;
        }
      }
    }
    &.home {
      background-color: #f3f3f3;
      border-radius: 4px 0 0 4px;
      transition: all 0.3s ease-out;
      a {
        padding-left: 12px;
      }
      &:hover {
        background-color: #333;
      }
    }
    &.current {
      cursor: default;
      opacity: 0.7;
    }
    
  }
  ion-icon {
    font-size: 16px;
  }
}

.splash-page {
  .quicklinks {
    @media (min-width: @screen-md-min) {
      padding: 0 60px;
    }
    .swiper-button-prev {
      left: 30px;
    }
    .swiper-button-next {
      right: 30px;
    }
  }
  
}

.delivery-icon {
  width: 40px;
}

footer .trustpilot-widget {
  margin-top: 40px;
  width: 96%;
}

body.home footer .trustpilot-widget {
  display: none;
}