.product-focus {
  position: relative;
  background: #000;
  &__image-main {
    transition: all 0.6s ease-in;
    &.inactive {
      opacity: 0;
    }
  }
  &__region-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.6s ease-in;
    &.active {
      opacity: 1;
    }
  }
  &__regions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: @screen-sm-min) {
      flex-direction: row;
    }
  }
  &__region {
    flex: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    text-align: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    @media (min-width: @screen-sm-min) {
      border-bottom: none;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      align-items: flex-start;
    }
    &:last-child {
      border-bottom: none;
      @media (min-width: @screen-sm-min) {
        border-right: none;
      }
    }
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3);
      .product-focus {
        &__region-content--closed {
          @media (min-width: @screen-sm-min) {
            top: 55%;
          }
          @media (min-width: @screen-md-min) {
            top: 65%;
          }
          @media (min-width: @screen-lg-min) {
            top: 75%;
          }
          .product-focus__expand-button {
            &:not([data-hover-bg]) {
              background: #fff;
              &:before {
                border-color: #000;
              }
              &:after {
                border-color: #000;
              }
            }
          }
        }
      }
    }
    &:not(.active):not(.collapsed) {
      .product-focus {
        &__region-content {
          animation: fadein 2s;
        }
      }
    }
    &.active {
      align-items: flex-end;
      padding-bottom: 20px;
      @media (min-width: @screen-sm-min) {
        align-items: center;
        padding: 20px 40px;
      }
      @media (min-width: @screen-md-min) {
        padding: 30px 80px;
      }
      @media (min-width: @screen-lg-min) {
        padding: 30px 120px;
      }
      &:hover {
        background: none;
        cursor: default;
      }
      .product-focus {
        &__region-content {
          display: block;
          @media (min-width: @screen-sm-min) {
            justify-content: center;
            align-items: flex-start;
            padding: 0;
          }
          &--closed {
            display: none;
          }
        }
        &__expand-button {
          animation: fadein 2s;
          margin-bottom: 20px;
          display: none;
          &--active {
            display: block;
            &:before {
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
          &:not([data-hover-bg]) {
            &:hover {
              background: #fff;
              &:before {
                border-color: #000;
              }
              &:after {
                border-color: #000;
              }
            }
          }
        }
        &__region-text-wrapper {
          text-align: left;
          animation: fadein 2s;
        }
        &__region-title {
          margin-bottom: 20px;
        }
        &__region-sub-title {
          display: block;
        }
        &__region-text {
          opacity: 0;
          height: 0;
          display: none;
        }
        &__region-text-extended {
          opacity: 1;
          position: static;
          visibility: visible;
          display: block;
          @media (min-width: @screen-md-min) {
            max-width: 80%;
          }
        }
        &__button {
          opacity: 1;
          position: static;
          visibility: visible;
          display: inline-block;
        }
      }
    }
    &.collapsed {
      flex: 0 0 7%;
      padding: 0;
      background: rgba(0, 0, 0, 0.2);
      opacity: 0.8;
      justify-content: center;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
      @media (min-width: @screen-sm-min) {
        width: 5%;
        flex: 0 0 5%;
        align-items: flex-start;
      }
      .product-focus {
        &__region-content {
          position: static;
          transform: none;
          width: 100%;
          height: 100%;
          padding: 10px 10px 10px 20px;
          display: none;
          @media (min-width: @screen-sm-min) {
            display: none;
            padding: 0;
          }
          &--closed {
            display: none;
          }
          &--collapsed {
            width: 100%;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (min-width: @screen-sm-min) {
              padding: 0;
              height: 100%;
              align-items: flex-start;
              justify-content: center;
            }
          }
        }
        &__region-text {
          opacity: 0;
          height: 0;
        }
        &__region-title {
          display: none;
          &--collapsed {
            display: block;
            animation: fadein 2s;
            opacity: 1;
            white-space: nowrap;
          }
        }

        &__region-text {
          display: none;
        }
        &__region-text-extended {
          display: none;
        }
        &__button {
          display: none;
        }
        &__expand-button {
          width: 20px;
          height: 20px;
          opacity: 0.7;
          &:after {
            width: 8px;
          }
          &:before {
            height: 8px;
          }
          @media (min-width: @screen-sm-min) {
            display: none;
            opacity: 0;
            position: absolute;
          }
        }
      }
    }
  }
  &__region-content {
    display: none;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    transition: padding 0.2s ease-out;
    &--collapsed {
      display: none;
    }
    &--closed {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      animation: fadein 2s;
      opacity: 1;
      transition: top 0.2s ease-out;
      position: relative;
      @media (min-width: @screen-sm-min) {
        flex-direction: column;
        top: 60%;
      }
      @media (min-width: @screen-md-min) {
        top: 70%;
      }
      @media (min-width: @screen-lg-min) {
        top: 80%;
      }
    }
  }
  &__expand-button {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    order: 2;
    flex-shrink: 0;
    transition: background 0.3s ease-in-out;
    @media (min-width: @screen-sm-min) {
      order: -1;
      margin-bottom: 30px;
    }
    &:after {
      content: '';
      width: 14px;
      border-width: 1px;
      border-color: inherit;
      border-style: solid;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: border-color 0.3s ease-in-out;
    }
    &:before {
      content: '';
      height: 14px;
      border-width: 1px;
      border-color: inherit;
      border-style: solid;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: border-color 0.3s ease-in-out;
    }
    &--active {
      display: none;
    }
  }
  &__region-text-wrapper {
    order: 1;
    text-align: left;
    @media (min-width: @screen-sm-min) {
      text-align: center;
    }
  }
  &__region-title {
    color: #fff;
    .main-font-bold();
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    font-size: 20px;
    line-height: 22px;
    &--collapsed {
      display: none;
      color: #fff;
      font-size: 14px;
      letter-spacing: 2px;
      opacity: 0;
      transition: opacity 3s ease-in-out;
      margin: 0;
      @media (min-width: @screen-sm-min) {
        writing-mode: tb-rl;
        position: relative;
        top: 5%;
        padding: 0;
      }
    }
  }
  &__region-sub-title {
    display: none;
    margin: 0 0 25px;
    color: #fff;
    letter-spacing: 1px;
  }
  &__region-text {
    color: #fff;
    letter-spacing: 1px;
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 16px;
    display: none !important;
    @media (min-width: @screen-sm-min) {
      display: block !important;
    }
  }
  &__region-text-extended {
    color: #fff;
    letter-spacing: 1px;
    margin: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  &__button {
    background: transparent;
    border: 1px solid #fff;
    font-size: 11px;
    padding: 8px 20px;
    margin-top: 20px;
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    display: none;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Updated version
.product-focus-2 {
  display: flex;
  width: 100%;
  max-height: calc(~'100vh - 80px');
  aspect-ratio: 1.4 / 1;
  @media (min-width: @screen-md-min) {
    aspect-ratio: 2 /1;
  }
  &__panel {
    flex: 0 1 20%;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-out;
    transition-delay: .3s;
    &.active {
      flex: 0 0 60%;
      cursor: default;
      img.icon {
        opacity: 0;
        transition-delay: 0s;
      }
      .content {
        width: 100%;
        animation: fadeIn 1s forwards;
        animation-delay: 0.6s;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    &.icon {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      transition: opacity 0.3s ease-in-out;
      transition-delay: 0.6s;
      opacity: 1;
    }
  }
  .content {
    position: absolute;
    left: 0px;
    bottom: 0px;
    color: #fff;
    opacity: 0;
    width: 0;
    overflow: hidden;
    animation: fadeOut .3s forwards;
    padding: 5%;
    h2 {
      font-size: 44px;
      line-height: 48px;
      max-width: 60%;
      margin: 0;
    }
    h4 {
      font-style: italic;
      font-size: 20px;
      margin-bottom: 0;
    }
    p {
      padding-bottom: 10px;
      max-width: 60%;
    }
    a {
      background-color: #fff;
      color: #111 !important;
      .main-font-bold();
      padding: 10px 50px;
      margin-bottom: 0;
    }
    @media (min-width: @screen-sm-min) {
      h2 {
        font-size: 28px;
        line-height: 30px;
        max-width: 95%;
      }
      p {
        max-width: 95%;
      }
    }
    @media (min-width: @screen-md-min) {
      h2 {
        font-size: 36px;
        line-height: 38px;
        max-width: 80%;
      }
      p {
        max-width: 80%;
      }
    }
    @media (min-width: @screen-lg-min) {
      h2 {
        font-size: 44px;
        line-height: 46px;
        max-width: 60%;
      }
      p {
        max-width: 60%;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
  99% {
    width: 100%;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
}
