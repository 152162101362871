@grid-break-1: 600px;
@grid-break-2: 960px;
@grid-break-3: 1400px;

.grid-panel {
  display: grid;

  &__item {
    text-align: center;
    a {
      width: 100%;
      text-decoration: none;
    }
    h2 {
      margin-top: 10px;
    }
  }
  &[data-items="1"] {
      @media(min-width:@grid-break-1) {
        grid-template-columns: 1fr;
      }
      @media(min-width:@grid-break-2) {
        grid-template-columns: 1fr;
      }
  }
  &[data-items="2"] {
      @media(min-width:@grid-break-1) {
        grid-template-columns: 1fr 1fr;
      }
      @media(min-width:@grid-break-2) {
        grid-template-columns: 1fr 1fr;
      }

  }
  &[data-items="2"][data-responsive="false"] {
    grid-template-columns: 1fr 1fr;
  }
  &[data-items="3"], &[data-items="9"] {
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width:@grid-break-2) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &[data-items="3"][data-responsive="false"] {
    grid-template-columns: repeat(3, 1fr);
  }
  &[data-items="4"] {
      @media(min-width:@grid-break-1) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media(min-width:@grid-break-2) {
        grid-template-columns: repeat(4, 1fr);
      }
  }
  &[data-items="4"][data-responsive="false"] {
    grid-template-columns: repeat(4, 1fr);
  }
  &[data-items="5"] {
    @media(min-width:@grid-break-1) {
      grid-template-columns: 1fr;
    }
    @media(min-width:@grid-break-2) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media(min-width:@grid-break-3) {
      grid-template-columns: repeat(5, 1fr);
      .grid-panel__links {
        width: 90%;
      }
    }
  }
  &[data-items="6"] {
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(min-width:@grid-break-2) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width:@grid-break-3) {
      grid-template-columns: repeat(6, 1fr);
      .grid-panel__links {
        width: 90%;
      }
    }
  }
  &[data-items="6"][data-responsive="false"] {
    grid-template-columns: repeat(6, 1fr);
    .grid-panel__item {
      .grid-panel__links {
        width: 90%;
      }
    }
  }
  &[data-items="8"] {
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(min-width:@grid-break-2) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media(min-width:@grid-break-3) {
      grid-template-columns: repeat(8, 1fr);
      .grid-panel__links {
        width: 90%;
      }
    }
  }
  &[data-items="8"][data-responsive="false"] {
    grid-template-columns: repeat(4, 1fr);
    .grid-panel__links {
      width: 90%;
    }
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  &[data-items="12"] {
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width:@grid-break-2) {
      grid-template-columns: repeat(6, 1fr);
      .grid-panel__links {
        width: 90%;
      }
    }
    @media(min-width:@grid-break-3) {
      grid-template-columns: repeat(12, 1fr);
    }
  }
  &[data-items="12"][data-responsive="false"] {
    grid-template-columns: repeat(4, 1fr);
    .grid-panel__links {
      width: 90%;
    }
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(8, 1fr);
    }
    @media(min-width:@grid-break-1) {
      grid-template-columns: repeat(12, 1fr);
    }
  }
  &[data-min-items="2"] {
    grid-template-columns: repeat(2, 1fr);
  }
  &[data-min-items="3"] {
    grid-template-columns: repeat(3, 1fr);
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    margin: 0 auto;
  }
  &__link {
    padding: 10px;
    background: #ccc;
    text-decoration: none;
    color: #fff;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    &:first-child {
      margin-top: 10px;
    }
    &:hover {
      text-decoration: none;
    }
    &--shop-now {
      background: @lovell-blue;
    }
    &--explore {
      background: #666;
    }
    &--splash {
      background: @lovell-light-gray;
      color: @lovell-dark-gray;
      padding: 2px;
      .main-font-bold();
      line-height: normal;
      font-size: 9pt;
      margin-bottom: 0px;
    }
    &--with-arrow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      &:after {
        content: '';
        background-image: url(../../responsive-images/right-arrow-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 6px;
        height: 11px;
        margin-left: 10px;
        background-position: 50% 50%;
      }
    }
  }
  &--image-hover {
    .grid-panel__item {
      position: relative;
      padding: 0;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 24px;
        z-index: 5;
        opacity: 1;
        transition: 0.3s opacity ease-in-out;
        margin: 0;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        left: 0;
        top: 0;
        transition: 0.3s background ease-in-out;
        position: absolute;
      }
      &:hover {
        h2 {
          opacity: 0;
        }
        &:after {
          background: transparent;
        }
      }
    }
  }
  &[data-image-behind] {
    .grid-panel__item {
      position: relative;
    }
    .grid-panel__links {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &--splash {
    .grid-panel__item {
      outline: 2px solid white !important;
    }
  }
  &--home {
    .grid-panel {
      &__item {
        border: 1px solid white;
      }
      &__link {
        background: @lovell-light-gray;
        color: @lovell-dark-gray;
        padding: 8px;
        .main-font-bold();
        line-height: normal;
        margin-bottom: 0px;
      }
    }
  }
  &--shaded {
    .grid-panel__item {
      a {
        position: relative;
        display: block;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.3);
          transition: 0.3s background ease-out;
        }
        &:hover {
          &:after {
            background: transparent;
          }
          h2, h3, .grid-panel__link {
            opacity: 0;
          }
        }
      }
      h2, h3 {
        color: #fff;
        transition: 0.3s opacity ease-in-out;
      }
      .grid-panel__link {
        border: 1px solid #fff;
        background: transparent;
        transition: 0.3s opacity ease-in-out;
      }
      &--unshaded {
        a {
          &:after {
            background: transparent;
          }
        }
      }
    }
  }
  &--overlay-content {
    .grid-panel__item {
      a {
        position: relative;
        display: block;
        .grid-panel__content {
          z-index: 5;
          position: absolute;
          bottom: 10%;
          left: 50%;
          transform: translateX(-50%);
          h2, h3 {
            margin: 0;
            color: #fff;
          }
          h2 {
            font-size: 26px;
          }
          h3 {
            font-size: 22px;
          }
          .grid-panel__link {
            margin-bottom: 0;
            margin-top: 10px;
            white-space: nowrap;
            border: 1px solid #fff;
            background: transparent;
            transition: 0.3s all ease-in-out;
            padding: 10px 30px;
          }
        }
      }
    }
    &--centred {
      .grid-panel__item {
        a {
          .grid-panel__content {
            top: 50%;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 85%;
          }
        }
      }
    }
  }
  &--overlay-content:not(.grid-panel--shaded) {
    .grid-panel__item {
      &:hover {
        a {
          .grid-panel__content {
            .grid-panel__link {
              background: #fff;
              color: #333;
            }
          }
        }
      }
    }
  }
  &--spaced {
    gap: 10px;
    @media(min-width: @screen-md-min) {
      gap: 20px;
    }
    @media(min-width: @screen-lg-min) {
      gap: 30px;
    }
    &.my {
      margin-top: 10px;
      margin-bottom: 10px;
      @media(min-width: @screen-md-min) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @media(min-width: @screen-lg-min) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    &.px {
      padding-left: 10px;
      padding-right: 10px;
      @media(min-width: @screen-md-min) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media(min-width: @screen-lg-min) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
