div {
  transition: opacity 0.3s linear;
}
.is-hidden {
  display: none !important;
}

// crossfade images - 2 images together, one with a data attrib of 'imgcrossfade="1"' will fade in and out over the first image
img[data-imgcrossfade='1'] {
  opacity: 0;
  position: absolute !important;
  top: 0;
  left: 0;
  @-webkit-keyframes crossfade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-moz-keyframes crossfade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-o-keyframes crossfade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes crossfade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  -webkit-animation: crossfade 3s infinite; /* Safari 4+ */
  -moz-animation: crossfade 3s infinite; /* Fx 5+ */
  -o-animation: crossfade 3s infinite; /* Opera 12+ */
  animation: crossfade 3s infinite; /* IE 10+, Fx 29+ */
}


.make-margins(@breakpoint, @size: 100, @decrement: 10) when (@size >= 0) {
    .make-margins(@breakpoint, @size - @decrement);

    @size-px: ~"@{size}px";

    .m-@{size}-@{breakpoint} {
        margin: @size-px;
    }
    .mt-@{size}-@{breakpoint} {
        margin-top: @size-px;
    }
    .mr-@{size}-@{breakpoint} {
        margin-right: @size-px;
    }
    .mb-@{size}-@{breakpoint} {
        margin-bottom: @size-px;
    }
    .ml-@{size}-@{breakpoint} {
        margin-left: @size-px;
    }
    .mx-@{size}-@{breakpoint} {
        margin-left: @size-px;
        margin-right: @size-px;
    }
    .my-@{size}-@{breakpoint} {
        margin-top: @size-px;
        margin-bottom: @size-px;
    }
}

.make-margins(xs);

@media (min-width: @screen-sm-min) {
    .make-margins(sm);
}

@media (min-width: @screen-md-min) {
    .make-margins(md);
}

@media (min-width: @screen-lg-min) {
    .make-margins(lg);
}

@media (min-width: @screen-xl-min) {
    .make-margins(xl);
}


.make-paddings(@breakpoint, @size: 100, @decrement: 10) when (@size >= 0) {
    .make-paddings(@breakpoint, @size - @decrement);

    @size-px: ~"@{size}px";

    .p-@{size}-@{breakpoint} {
        padding: @size-px;
    }
    .pt-@{size}-@{breakpoint} {
        padding-top: @size-px;
    }
    .pr-@{size}-@{breakpoint} {
        padding-right: @size-px;
    }
    .pb-@{size}-@{breakpoint} {
        padding-bottom: @size-px;
    }
    .pl-@{size}-@{breakpoint} {
        padding-left: @size-px;
    }
    .px-@{size}-@{breakpoint} {
        padding-left: @size-px;
        padding-right: @size-px;
    }
    .py-@{size}-@{breakpoint} {
        padding-top: @size-px;
        padding-bottom: @size-px;
    }
}

.make-paddings(xs);

@media (min-width: @screen-sm-min) {
    .make-paddings(sm);
}

@media (min-width: @screen-md-min) {
    .make-paddings(md);
}

@media (min-width: @screen-lg-min) {
    .make-paddings(lg);
}

@media (min-width: @screen-xl-min) {
    .make-paddings(xl);
}