// top bar ****************************************************************************************
.top-bar {
  min-height: 33px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;

  .container {
    height: 100%;
  }
}

.top-bar--02 {
  display: none;
  overflow: visible;
}

.currency-current {
  position: relative;
  z-index: 100;
}

// header message area *******************************************************************
/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie9 img[src*='.svg'] {
  width: 100%;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*='.svg'] {
    width: 100%;
  }
}

#search-bar {
  @media (min-width: @screen-md-min) {
    display: none;
  }
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 50px;
  transition: top 0.2s;
  z-index: 10;
  background: #fff;
  &.active {
    top: 60px;
  }
  #drop-down-search-form {
    .flex-container(nowrap, flex-start, flex-start);
    width: 100%;
  }
  input {
    .flex-item(1,1,auto);
    width: 100%;
    padding: 10px;
    margin: 0;
    height: 50px;
    border: 1px solid @navbar-default-toggle-border-color;
    border-left: 0;
    border-right: 0;
    -webkit-appearance: none;
    border-radius: 0;
    font-size: 18pt;
    @media (min-width: @screen-sm-min) {
      background: url('@{images-path}search.svg') no-repeat -5px -5px;
      background-size: 65px 65px;
      padding-left: 65px;
    }
  }
  button {
    margin: 0;
    .flex-item(0,0,120px);
    background-color: @lovell-blue;
    color: white;
    width: 120px;
    height: 50px;
    border: 0;
    letter-spacing: 1px;
    &:after {
      content: ' ';
      width: 15px;
      height: 15px;
      background: url('../../responsive-images/drop-down-triangle-white.svg');
      background-size: 15px 15px;
      position: absolute;
      top: -3px;
      right: 90px;
      transform: translateX(50%);
    }
  }
}

.breadcrumb {
  > a {
    text-decoration: none;
    color: @text-main;
  }
}

// styles for minimal header (checkout etc)
#minimal-header {
  padding: 20px 0;
  .logo {
    float: left;
    width: 220px;
    max-width: 45%;
    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }
  .message {
    max-width: 50%;
    float: right;
    padding: 5px;
    .phone {
      font-weight: bold;
      font-size: 14pt;
      display: block;
    }
    @media (min-width: @screen-sm-min) {
      .phone {
        display: inline;
      }
      padding: 10px;
    }
  }
}

nav.navbar #navbar,
nav.navbar .navbar-close {
  transition: left 0.15s ease-in-out;
}

nav.navbar .navbar-sidebar {
  transition: opacity 0.2s ease-in;
  transition-delay: 0.15s;
}



nav.navbar {
  height: auto;
  @media (min-width: @screen-md-min) {
    position: sticky;
    top: 0;
    z-index: 1001;
  }

  a {
    text-decoration: none;
  }

  .menu-close {
    width: 10vw;
    height: 10vw;
    position: absolute;
    top: calc(~'70px - 10vw');
    left: 90vw;
    background: #000;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: top 0.3s ease-out;

    @media (min-width: @screen-sm-min) {
      width: 40px;
      height: 40px;
      top: 30px;
      left: 40vw;
    }

    svg {
      color: #fff;
    }

    &.active {
      top: 70px;

      @media (min-width: @screen-sm-min) {
        left: 40vw;
        top: 70px;
      }
    }
  }

  .navbar-close {
    height: 70px;
    width: @menu-offset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
    position: fixed;
    top: 0;
    left: calc(~'100vw');
    z-index: 101;

    @media (min-width: @screen-sm-min) {
      height: 70px;
    }

    a {
      display: contents;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .navbar-mask {
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(~'100vh - 70px');
    width: @menu-offset;
    background: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 101;
    transition: opacity 0.2s linear;
    transition-delay: 0.2s;

    @media (min-width: @screen-sm-min) {
      width: @menu-offset-sm;
    }
  }

  #navbar {
    top: 0px;
    padding-top: 0;
    left: 100vw;
    width: calc(~'100vw - ' @menu-offset);
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    z-index: 101;
    height: 100vh;
    background-color: #fff;

    @media (min-width: @screen-sm-min) {
      width: @menu-offset-sm;
      border-top: none;
      border-right: none;
      top: 0;
    }

    @media (min-width: @screen-md-min) {
      background: transparent;
      flex-direction: row;
      overflow-y: initial;
    }

    .navbar-title {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      .main-font-bold();
      background: #fff;

      @media (min-width: @screen-sm-min) {
        height: 70px;
      }

      .navbar-back {
        margin-left: 20px;
        display: none;
        img {
          width: 26px;
          height: 26px;
        }
      }

      a.mainNav,
      div.mainNav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 30px;
        .main-font-bold();
        text-align: right;

        .mainNavText {
          text-align: right !important;
          width: 100%;
        }

        .back-button {
          display: none !important;
        }
      }

      .dropdown-header {
        display: flex;
        list-style: none;
        justify-content: space-between;
        width: 100%;
        padding: 0 30px;
        .main-font-bold();

        .back-button {
          display: none !important;
        }
      }
    }

    #megaMenuTabs {
      height: calc(~'100vh - 70px');
      overflow: auto;
      flex: 1 1 auto;
      padding-bottom: 100px;

      @media (min-width: @screen-sm-min) {
        height: calc(~'100vh - 70px');
      }

      @media (min-width: @screen-md-min) {
        height: auto;
        overflow: visible;
        padding-bottom: 0;
        width: 100%;
      }
    }

    .locale {
      position: fixed;
      top: 70px;
      width: calc(~'100vw - ' @menu-offset);
      height: calc(~'100vh - 70px');
      left: 100vw;
      background: #fff;
      overflow: auto;
      transition: left 0.2s ease-out;

      &.active {
        left: @menu-offset;

        @media (min-width: @screen-sm-min) {
          left: @menu-offset-sm;
        }
      }

      .currency-current {
        display: none;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        cursor: pointer;

        img {
          margin-right: 10px;
          width: 22px;
          height: 22px;
        }
      }

      .currency-options {
        @media (min-width: @screen-sm-min) {
          width: @menu-offset-sm;
        }

        &__option {
          padding: 20px;
          border-top: 1px solid #e6e6e6;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-right: 10px;
            width: 22px;
            height: 22px;
          }

          &:last-child {
            border-bottom: none;
          }
          .currencyFlags();
        }
      }

      .sod_select {
        height: 100%;

        .sod_label {
          height: 100%;
          justify-content: center;
          border-top: none;
          border-bottom: none;
          border-right: none;
          border-left: 1px solid #ccc;
          background-repeat: no-repeat;
          background-position: left center;

          .currency-flag {
            margin-right: 10px;
          }

          &:after {
            content: none;
          }
        }
      }
    }

    @media (min-width: @screen-md-min) {
      width: 100%;
      display: flex;
      justify-content: center;
      position: static;
      max-height: 70px;
    }

    &.active {
      @media (min-width: @screen-sm-min) {
        top: 0;
        right: auto;
      }
    }

    ul.nav {
      border-top: none;
      width: 100%;
      border-bottom: none;
      margin: 0;

      @media (min-width: @screen-md-min) {
        width: 100%;
        justify-content: center;
        margin-bottom: 0;
        position: static;
        display: flex;
        align-items: center;
      }

      & > li {
        border-bottom: none;
        border-top: 1px solid #e6e6e6;
        position: static;

        &:last-child {
          border-bottom: none;
        }

        &.current {
          border-top: none;
        }

        &.active {
          .dropdown-menu {
            display: flex;
          }
        }

        @media (min-width: @screen-md-min) {
          flex-shrink: 1;
          flex-grow: 0;
          padding: 0;
          height: 70px;

          .triangle {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ccc;
            position: relative;
            display: none;
            margin: -9px auto 0;

            &:after {
              content: '';
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid #fff;
              position: absolute;
              left: -7px;
              top: 1px;
            }
          }

          &:hover {
            .triangle {
              display: block;
            }
          }

          a.saleHeading:hover,
          div.saleHeading:hover {
            background-color: #ea1b1b !important;
            color: #fff;
            .mainNavText {
              color: #ffffff;
            }
          }
        }

        &.main-nav-currency {
          .mainNavText {
            display: flex;
            align-items: center;
          }

          .currency-flag {
            img {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }

        a.mainNav,
        div.mainNav {
          padding: 20px 10px;
          color: #111;
          font-weight: normal;
          font-size: 1.4rem;
          white-space: nowrap;
          cursor: pointer;
          .flex-container(nowrap, center,space-between);

          .main-font-bold();
          font-size: 16px;
          padding: 15px 20px;

          @media (min-width: @screen-md-min) {
            border-bottom: none;
            padding: 10px 10px;
            color: inherit;
            .main-font();
            font-size: 14px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            .arrow-right {
              display: none;
            }

            &:hover {
              border-bottom: none;
              background-color: transparent;
            }
          }

          @media (min-width: @screen-lg-min) {
            padding: 10px 15px;
            .arrow-right {
              display: none;
            }
          }

          img.arrow-right {
            width: 6px;
          }
          img.arrow-left {
            transform: rotate(180deg);
            width: 8px;
            margin-right: 10px;
          }

          .back-button {
            display: none;
          }
        }

        ul.dropdown-menu {
          border: none;
          display: none;
          padding: 0;

          @media (min-width: @screen-md-min) {
            border-left: none;
            border-right: none;
            border-bottom: none;
          }
          li {
            list-style: none;
            border-top: 1px solid @lovell-border-color;
            display: none;
            border-top: 1px solid #e6e6e6;
            a, &.shopAll span {
              padding: 15px 20px;
              color: #111;
              font-size: 14px;
              &:hover {
                background: #f3f3f3;
              }
              .flex-container(nowrap,center,flex-start);
            }
          }

          li.dropdown-header {
            .flex-container(nowrap,center,space-between);
            padding: 20px 15px;
            cursor: pointer;
            img {
              width: 8px;
            }
            padding: 15px 20px;

            &.current {
              padding: 20px;
            }

            img.arrow-right {
              width: 6px;
            }
            .back-button {
              img {
                transform: rotate(180deg);
                width: 8px;
                margin-right: 10px;
              }
            }
            &.current {
              background: @lovell-light-gray;
              padding: 20px 10px;
            }
          }

          li a.mmLink {
            padding: 15px 20px;
          }
        }

        .dropdown-menu {
          @media (min-width: @screen-md-min) {
            border: none;
            background-color: #ffffff;
            padding: 0;
            max-height: none;
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            background: white;
            z-index: 1001;
            overflow: auto;
            display: none;
            box-shadow: 0 20px 30px rgba(0,0,0,0.2);
          }

          .container {
            display: flex;
            justify-content: space-between;
            align-content: stretch;
          }

          .dropdown-header {
            font-size: 14px;

            .back-button {
              .main-font();
              font-size: 16px;
            }

            &.current {
              font-family: @font-main !important;
              font-size: 16px;
            }
          }

          li {
            a {
              font-size: 14px;
            }
          }

          li.shopAll a {
            font-style: normal !important;
            font-family: @font-main !important;
            font-size: 14px;
          }

          .menu-column {
            margin: 40px 0;
            padding: 0px 30px;
            border: none;
            width: auto;

            @media (min-width: @screen-md-min) {
              align-self: stretch;
              min-height: 100%;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              flex-shrink: 1;
              flex-basis: 20%;
            }

            &:first-child {
              border-left: none;
            }

            &.menu-banner-column {
              flex-grow: 0;
            }

            border-left: 1px solid #eee;

            a {
              padding: 4px 0;
            }

            .ffHeading,
            .ffSubHeading {
              .main-font-bold();
              font-size: 14px;
              color: #000;
              text-transform: capitalize;
              border: none;
              padding: 4px 0;

              a {
                padding: 0;

                &:hover {
                  color: #333;
                }
              }
            }

            .mmLink {
              font-size: 13px;
              .main-font();

              &:hover {
                color: #333;
              }
            }

            .megamenu-section-heading {
              margin-bottom: 15px;
              &:not(:first-child) {
                margin-top: 10px;
              }
            }
          }

          .menu-column.menu-banner-column,
          .menu-column.menu-page-column {
            display: none;
            padding: 0px;
            margin: 0;
            border-left: none;

            @media (min-width: @screen-lg-min) {
              display: block;
            }

            .main-banner-image {
              a {
                padding: 0;

                img {
                  height: 100%;
                  width: auto;
                }
              }
            }

            .main-banner-elements {
              display: block;
              position: relative;
              padding: 0;

              .main-banner-main-title {
                color: #000000;
                font-size: 1.2rem;
                text-transform: uppercase;
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.open {
    .navbar-header {
      border-bottom: none;
    }
  }

  .navbar-header {
    min-width: 300px;
    z-index: 1001;
    top: 0;
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    position: fixed;
    left: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    align-items: center;
    @media (min-width: @screen-sm-min) {
      gap: 20px;
    }

    @media (min-width: @screen-md-min) {
      justify-content: space-between;
      position: relative;
      padding: 0;
    }

    .navbar-toggle--02 {
      display: none;
    }

    .header-logo {
      text-align: center;
      display: flex;
      align-items: center;
      align-self: stretch;
      justify-content: flex-start;
      flex: 0 0 auto;
      @media (min-width: @screen-md-min) {
        padding: 0 30px;
      }
      a {
        display: inline-block;

        img {
          height: 26px;
          width: 150px;
          object-fit: contain;
          object-position: left;
          display: block;
          @media (min-width: @screen-sm-min) {
            height: 30px;
          }
        }
      }
    }

    .header-search {
      flex: 0 0 33.333333%;

      #search-form {
        display: flex;
        align-items: center;

        .divider {
          width: 1px;
          height: 18px;
          background: #000;
          margin-left: 10px;
        }

        input#search {
          border: none;
          background: none;
          padding-left: 12px;
          font-size: 14px;
          line-height: 10px;
          text-transform: capitalize;
          color: #000000;

          &::placeholder {
            color: #000;
          }
        }

        button#search-submit {
          display: none;
        }

      }

      #livesearchresults {
        position: absolute;
        z-index: 1000;
        top: 120px !important;
        left: auto !important;
        right: 0px;
        width: 300px;

        &:empty {
          display: none;
        }
        #livesearchtable {
          animation: fadein 0.5s;
          display: flex;
          flex-direction: column;
          .empty-text {
            background: #111;
            color: #fff;
            padding: 10px;
          }
          .header {
            .view-all-results {
              margin: 0;
              background: #000;
              position: relative;
              font-size: 16px;
              color: #fff;
              padding: 10px;
              text-align: center;
              .lovell-button {
                margin-bottom: 0;
                padding: 4px;
              }
            }
          }

          .results {
            overflow: auto;
            max-height: 400px;
            border: 1px solid #ccc;
            background: #fff;
            &.no-results {
              .tagLink {
                padding: 0;
              }
            }

            .taglink {
              padding: 20px 20px 0;
              padding: 0;
              a {
                display: flex;
                align-items: center;
                background: #f2f2f2;
                justify-content: space-between;
                padding: 5px;
                color: #333;
                margin: 10px;
                font-size: 12px;

                img {
                  width: 10px;
                  height: 10px;
                  display: block;

                  &.white {
                    display: none;
                  }
                }

                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }

            .results-list {
              padding: 20px !important;
              &.no-results {
                padding: 0 !important;
              }

              .media {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .media-left {
                  display: block;
                  flex: 0 0 30%;

                  a {
                    display: block;

                    img {
                      display: block;
                      width: 100%;
                      height: auto;
                    }
                  }
                }

                .media-body {
                  background: transparent !important;

                  .livesearch-colour {
                    display: none;
                  }

                  a {
                    color: #333;
                    .main-font-bold();
                  }

                  a:last-child {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .header-services {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      flex: 0 0 auto;
      gap: 16px;

      @media (min-width: @screen-md-min) {
        padding: 0 30px;
        
      }

      &.search-active {
        .header-search {
          #search-form {
            width: 300px;
            padding: 10px 20px;
          }
        }
      }

      .header-blog {
        font-size: 14px;
        img {
          width: 20px;
          height: 20px;
          display: block;
          @media (min-width: @screen-md-min) {
            width: 18px;
            height: 18px;
          }
        }
        &:empty {
          display: none;
        }
      }

      .header-user,
      .header-wishlist {
        display: none;
        @media (min-width: @screen-md-min) {
          display: block;
        }
      }

      .header-currency {
        position: relative;
        display: none;
        @media (min-width: @screen-md-min) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .current-currency {
          color: #000000;
          cursor: pointer;
          .main-font-bold();
          .current-currency-flag {
            display: none;
          }
        }

        .locale {
          position: absolute;
          top: 100%;
          left: 50%;
          background: #fff;
          z-index: 100;
          transform: translateX(-50%);
          display: none;
          border-top: 10px solid transparent;
          animation: fadeout 0.5s;

          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: #000;
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
          }

          &.active {
            display: block;
            animation: fadein 0.5s;
          }

          .currency-current {
            display: none;
            white-space: nowrap;
            border: 1px solid #ccc;
            padding: 20px;

            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
          }

          .currency-options {
            background: #fff;
            border: 1px solid #ccc;
            white-space: nowrap;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            &__option {
              display: flex;
              align-items: center;
              padding: 20px;
              border-bottom: 1px solid #ccc;
              cursor: pointer;

              &:hover {
                background: #f3f3f3;
              }

              &:first-child {
                background: #111;
                color: #fff;
                cursor: default;

                &:hover {
                  background: #111;
                }
              }

              &:last-child {
                border-bottom: none;
              }
              .currencyFlags();
            }

            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
              max-width: none;
            }
          }

          .sod_select {
            .sod_label {
              display: none;
            }

            .sod_list_wrapper {
              display: block;
              position: static;
              border: 1px solid #ccc;
              border-top: 1px solid #000;

              .sod_option {
                padding: 20px;

                &:first-child {
                  background: #000;
                  color: #fff;
                }
              }
            }
          }
        }
      }

      #header-basket {
        position: relative;

        #basketContents {
          #icon-checkout {
            display: block;
            img {
              width: 22px;
              height: 22px;
            }
          }

          #basketSummary {
            font-size: 0;

            #basket-total-items {
              left: auto;
              right: -10px;
              top: -6px;
              background: #000;
              font-size: 9px;
              cursor: default;
              position: absolute;
              width: 16px;
              height: 16px;
              border-radius: 10px;
              color: white;
              font-weight: bold;
              text-align: center;
              line-height: 16px;
            }
          }
        }

      }

      .header-search {
        flex: 0 0 auto;
        display: flex;

        ion-icon {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }

        #search-form {
          position: absolute;
          top: 70px;
          width: 0px;
          overflow: hidden;
          height: 50px;
          right: 0px;
          background: #fff;
          z-index: 111;
          display: flex;
          padding: 0px 0px;
          justify-content: space-between;
          transition: all 0.3s ease-out;
          border-left: 1px solid @border-color;
          border-bottom: 1px solid @border-color;
          #search {
            padding-left: 0;
            background: transparent !important;

            flex: 1 1 auto;
            margin-right: 20px;
            height: 100%;
          }
          .close-search {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .header-nav {
      flex: 1 1 auto;
      align-self: stretch;
      display: flex;
      @media (min-width: @screen-md-min) {
        display: block;
        overflow: hidden;
      }
    }

    .navbar-toggle {
      @media (min-width: @screen-md-min) {
        display: none;
      }
      &.pwa-back-button {
        display: none;
      }

      .navbar-icon {
        text-align: center;
        height: auto;
        width: 100%;
        align-self: stretch;
        .flex-container(nowrap, center, center);
        flex-direction: column;
        cursor: pointer;
        img {
          margin: 0;
          height: 28px;
        }
      }
    }
  }

  #search-bar {
    height: 69px;
    z-index: 1011;
    position: fixed;
    top: -69px;
    display: flex;
    align-items: center;
    padding: 0;
    transition: top 0.3s ease-out;

    ion-icon {
      width: 26px;
      height: 26px;
      margin: 0px;
      flex: 0 0 50px;
      text-align: center;

      &[name="close"] {
        cursor: pointer;
      }
    }

    input {
      height: 100%;
      padding: 0px;
      background: none;
      border: none;
      font-size: 18px;
      .main-font();
    }

    &.active {
      top: 0;
    }
  }
}

body.ios {
  padding-top: calc(~'70px + 10pt'); // fallback with no env/constant support
  padding-top: calc(~'70px + constant(safe-area-inset-top)');
  padding-top: calc(~'70px + env(safe-area-inset-top)');

  nav.navbar {
    .navbar-header {
      .header-mobile {
        border-top: 10pt solid #fff; // fallback with no env/constant support
        border-top: constant(safe-area-inset-top) solid #fff;
        border-top: env(safe-area-inset-top) solid #fff;
        height: calc(~'70px + 10pt'); // fallback with no env/constant support
        height: calc(~'70px + constant(safe-area-inset-top)');
        height: calc(~'70px + env(safe-area-inset-top)');

        .navbar-toggle {
          &.pwa-back-button {
            display: flex;
          }
        }
      }
    }

    #navbar {
      padding-top: 10pt; // fallback with no env/constant support
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);

      &.active {
        z-index: 2000;
      }

      #megaMenuTabs {
        height: calc(
          ~'100vh - 70px - 10pt'
        ); // fallback with no env/constant support
        height: calc(~'100vh - 70px - constant(safe-area-inset-top)');
        height: calc(~'100vh - 70px - env(safe-area-inset-top)');
      }
    }

    #search-bar {
      border-top: 10pt solid #fff; // fallback with no env/constant support
      border-top: constant(safe-area-inset-top) solid #fff;
      border-top: env(safe-area-inset-top) solid #fff;
      height: calc(~'70px + 10pt'); // fallback with no env/constant support
      height: calc(~'70px + constant(safe-area-inset-top)');
      height: calc(~'70px + env(safe-area-inset-top)');
      top: calc(~'(70px + 10pt) * -1'); // fallback with no env/constant support
      top: calc(~'(70px + constant(safe-area-inset-top)) * -1');
      top: calc(~'(70px + env(safe-area-inset-top)) * -1');

      &.active {
        top: 0;
      }
    }
  }

  .mc-banner {
    border-top: 10pt solid #fff; // fallback with no env/constant support
    border-top: constant(safe-area-inset-top) solid #fff;
    border-top: env(safe-area-inset-top) solid #fff;
  }

  &.order {
    padding-top: 0;
  }

  #minimal-header {
    .container {
      display: flex;
      align-items: center;
    }

    .pwa-back-button {
      display: block;
      padding-left: 10px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .logo {
      max-width: none;
      margin-right: 40px;
    }
  }
}



#mini-basket-panel {
  left: auto;
  top: 100%;
  animation: fadein 0.5s;
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.3);
  position: absolute;
  display: none;
  width: 400px;
  right: 0;
  background: white;
  border: none;
  border-top: 9px solid transparent;
  z-index: 1001;
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #000;
    position: absolute;
    top: -4px;
    right: -1px;
    transform: translateX(-50%) rotate(45deg);
  }
  &:after {
    content: none;
  }
  @media (min-width: @screen-lg-min) {
    width: 390px;
  }
  &.active {
    @media (min-width: @screen-md-min) {
      display: block;
    }
  }
  .basketInfo {
    text-align: left;
    background: #fff;
    p {
      margin: 0;
      font-size: 10pt;
    }
    .numberInBasket {
      display: block;
      padding: 20px;
      margin: 0;
      border: none;
      font-size: 1.4rem;
      background: #000;
      color: #ffffff;
    }
    .empty-basket {
      padding: 20px;
      border: 1px solid #ccc;
      background: #000;
      color: #fff;
    }
    .basketUpdate {
      display: none;
    }
  }
  .basket-item {
    padding-bottom: 0px;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    height: 145px;
    .flex-container(nowrap, stretch, flex-start);
    .thumb {
      .flex-item(0, 0, 80px, center);
      position: relative;
      :before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #eee;
        mix-blend-mode: darken;
      }
      img {
        aspect-ratio: 1;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: #eee;
        }
      }
      img {
        max-width: 80px;
        height: auto;
      }
    }
    .content {
      padding: 10px;
      .flex-item(1, 1, auto, stretch);
      .flex-container(nowrap, flex-start, flex-start);
      justify-content: space-between;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      .title {
        margin: 0;
        font-size: 11pt;
        margin-bottom: 5px;
        a {
          color: #333;
        }
      }
      .product-size {
        display: inline-block;
      }
      .basket-psnl-label {
        font-size: 9pt;
      }
      .basket-qty {
        display: inline-block;
      }
      .mini-basket-product-options {
        font-size: 10pt;
        > div {
          margin-right: 10px;
        }
      }
      .compact-basket-price-and-remove {
        .flex-container(nowrap, center, space-between);
        width: 100%;
        .compact-basket-price {
          justify-content: flex-end;
          .original {
            display: inline-block;
            font-size: 9pt;
            text-decoration: line-through;
            margin-right: 5px;
          }
          .offer {
            display: inline-block;
            color: red;
            font-size: 10pt;
          }
        }
        .basket-remove-item {
          font-size: 9pt;
        }
      }
    }
  }
  .mini-basket-total-line,
  .mini-basket-code-line {
    font-size: 0;
    margin-top: -1px;
    margin-bottom: 20px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 20px 10px;
    border-bottom: none;
    margin-bottom: 0;
    span {
      width: 50%;
      display: inline-block;
      font-size: @font-size-base;
      &.mini-basket-total-value {
        font-size: @font-size-large;
        text-align: right;
      }
    }
  }
  .mini-basket-code-line {
    border-top: 0;
    padding: 0 0 8px;
    span {
      &.mini-basket-code-value {
        font-size: @font-size-large !important;
        text-align: right;
      }
    }
  }
  .mini-basket-button {
    width: 100%;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: @font-size-large;
    line-height: @font-size-large;
    margin-top: 10px;
    font-weight: bold;
    img {
      margin-left: 15px;
      width: 9px;
      margin-bottom: 2px;
    }
    &.mini-basket-button-checkout {
      background: @lovell-green;
      color: white;
      border: 2px solid @lovell-green;
    }
    &.mini-basket-button-basket {
      border: 1px solid @lovell-gray;
      color: #333;
    }
  }
  .media-list {
    // set height to 0 initially so that you don't see a long list that suddenly shortens when the jQuery kicks in
    height: 0;
    .fa {
      position: absolute;
      top: -14px;
      right: 0;
      font-size: 30px;

      &.fa-angle-down {
        bottom: -20px;
        top: auto;
      }
    }
    &.slick-slider {
      margin-top: 10px;
    }
    overflow: auto !important;
    height: auto !important;
    max-height: 268px;

    .basket-item {
      margin: 0;
      padding: 5px 10px 0;
      border: none;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      height: auto;

      .basket-remove-item {
        a {
          font-size: 13px;
          color: #c0c0c0;
        }
      }
    }

    basket-item:first-child {
      padding-top: 10px;
    }

    .basket-item:last-child {
      padding-bottom: 10px;
    }
  }
  #scroll-bar-mini-basket-list {
    display: none;
  }
  #compactBasketButtons {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
    border: 1px solid #ccc;
    border-top: 1px solid #e6e6e6;

    a {
      margin: 5px;
      border-radius: 3px;
      font-size: 14px;

      img {
        display: none;
      }

      &.min-basket-button-basket {
        border-color: #999;
      }
    }
  }
}



// team logos on shirt menu items
body.LS a[data-content="Arsenal"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Arsenal.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Aston Villa"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Aston-Villa.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Burnley"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Burnley.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Chelsea"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Chelsea.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Everton"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Everton.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Fulham"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Fulham.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Liverpool"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Liverpool.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Manchester City"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Manchester-City.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Manchester United"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Manchester-United.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Newcastle United"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Newcastle-United.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Tottenham Hotspur"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Tottenham-Hotspur.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="West Ham United"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/West-Ham-United.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Wolves"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Premier\ League/Wolves.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Atletico Madrid"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/La\ Liga/Atletico-Madrid.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Barcelona"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/La\ Liga/Barcelona.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Girona"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/La\ Liga/Girona.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Real Madrid"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/La\ Liga/Real-Madrid.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Valencia"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/La\ Liga/Valencia.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Olympique Lyon"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Ligue\ 1/Olympique-Lyonnais.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Olympique Marseille"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Ligue\ 1/Olympique-Marseille.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Paris Saint-Germain"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Ligue\ 1/Paris-Saint-Germain.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="RC Lens"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Ligue\ 1/RC-Lens.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Stade Rennais"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Ligue\ 1/Stade-Rennais.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Atlanta"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Atlanta.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Inter Miami"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Inter-Miami.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="LA Galaxy"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Los-Angeles-Galaxy.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Los Angeles FC"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Los-Angeles-FC.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Portland Timbers"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Portland-Timbers.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Sporting Kansas City"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Sporting-Kansas-City.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Seattle Sounders"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/MLS/Seattle-Sounders.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Bayern Leverkusen"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Bayern-Leverkusen.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Bayern Munich"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Bayern-Munich.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Borussia Dortmund"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Borussia-Dortmund.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Borussia Monchengladbach"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Borussia-Mönchengladbach.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Eintracht Frankfurt"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Eintracht-Frankfurt.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Hertha Berlin"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Hertha-Berlin.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="RB Leipzig"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/RB-Leipzig.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Wolfsburg"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Bundesliga/Wolfsburg.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="AC Milan"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Serie\ A/AC-Milan.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="AS Roma"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Serie\ A/AS-Roma.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Inter Milan"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Serie\ A/Inter-Milan.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Juventus"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Serie\ A/Juventus.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Ajax"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Ajax.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Benfica"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Benfica.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Fenerbahce"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Fenerbahce.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Galatasaray"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Galatasaray.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Porto"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Porto.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Rangers"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/Featured\ Clubs/Rangers.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Argentina"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Argentina.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Belgium"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Belgium.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Brazil"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Brazil.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="England"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/England.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="France"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/France.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Germany"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Germany.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Ireland"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Ireland.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Italy"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Italy.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Jamaica"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Jamaica.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Mexico"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Mexico.svg?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Netherlands"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Netherlands.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Nigeria"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Nigeria.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Portugal"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Portugal.png?width=20);
    background-size: contain;
  }
}
body.LS a[data-content="Spain"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Spain.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Scotland"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Scotland.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Hungary"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Hungary.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Switzerland"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Switzerland.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Croatia"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Croatia.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Denmark"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Denmark.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Serbia"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Serbia.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Poland"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Poland.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Austria"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Austria.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Slovakia"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Slovakia.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Ukraine"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Ukraine.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Turkey"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Turkey.png?width=20);
    background-size: contain;
  }
}

body.LS a[data-content="Czech Republic"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url(https://cdn.lovellsports.com/images/club-badges/International/Czech-Republic.png?width=20);
    background-size: contain;
  }
}

