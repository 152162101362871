.switch {
  position: relative;
  display: flex;
  align-items: center;
  input {
    display:none;
  }
  input:checked + .switch__control {
    background-color: @lovell-blue;
  }
  input:focus + .switch__control {
    box-shadow: 0 0 1px @lovell-blue;
  }
  input:checked + .switch__control:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  &__control {
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 60px;
    height: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &--round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  &__label {
    margin-left: 10px;
    cursor: pointer;
  }
}
